<template>
    <div v-if="item.called == '1'">
        <v-btn text @click="markCalled(0)">
            <v-icon color="teal">mdi-check</v-icon>
            <span>{{ item.called_user.replace(/ .*/, '') }}</span>
        </v-btn>
        <p v-if="item.called_date" class="text-sm">{{ item.called_date | formatDate }} <span
                v-if="userRole == 'admin' || userRole == 'superuser'">{{ item.called_date |
                    formatTime }}</span></p>
    </div>
    <v-tooltip v-else color="teal" right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon text @click="markCalled(1)">
                <v-icon color="grey" dark v-bind="attrs" v-on="on">
                    mdi-phone-outgoing
                </v-icon>
            </v-btn>
        </template>
        <span>Schimba status sunat</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        item: Object,
        username: String,
        userRole: String
    },
    data() {
        return {}
    },
    methods: {
        markCalled(called) {
            let user = called == 1 ? this.username : null;
            this.$store.dispatch("markCalledCerereGard", {
                id: this.item.id_cerere,
                called: called,
                user: user
            });
        },
    }
}
</script>