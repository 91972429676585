import Vue from "vue";
import router from "../router";
// import M from 'materialize-css';

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
  //
  // **** Wordpress ****
  //
  fetchWPCereri({ commit }) {
    commit("setLoadingCereri", true);
    let url = process.env.VUE_APP_WP_API_HOST;
    // url+='orders?status=processing'
    url += "orders?per_page=15&status=processing";
    let base64encodedData = new Buffer(
      process.env.VUE_APP_WP_API_KEY + ":" + process.env.VUE_APP_WP_API_SECRET
    ).toString("base64");
    Vue.http
      .get(url, {
        headers: {
          Authorization: "Basic " + base64encodedData,
        },
      })
      .then(function (response) {
        // console.log(response)
        commit("loadWPCereri", response.body);
        commit("loadWPDetails", response.headers.get("x-wp-totalpages"));
        commit("setLoadingCereri", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingCereri", false);
      });
  },
  fetchMoreWPCereri({ commit }, page) {
    commit("setLoadingCereri", true);
    let url = process.env.VUE_APP_WP_API_HOST;
    url += "orders?per_page=15&status=processing&page=" + page;
    let base64encodedData = new Buffer(
      process.env.VUE_APP_WP_API_KEY + ":" + process.env.VUE_APP_WP_API_SECRET
    ).toString("base64");
    Vue.http
      .get(url, {
        headers: {
          Authorization: "Basic " + base64encodedData,
        },
      })
      .then(function (response) {
        commit("loadMoreWPCereri", response.body);
        commit("setLoadingCereri", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingCereri", false);
      });
  },
  fetchWPCerere({ commit }, id) {
    commit("setLoadingCerere", true);
    let url = process.env.VUE_APP_WP_API_HOST;
    url += "orders/" + id;
    let base64encodedData = new Buffer(
      process.env.VUE_APP_WP_API_KEY + ":" + process.env.VUE_APP_WP_API_SECRET
    ).toString("base64");
    Vue.http
      .get(url, {
        headers: {
          Authorization: "Basic " + base64encodedData,
        },
      })
      .then(function (response) {
        commit("loadWPCerere", response.body);
        commit("setLoadingCerere", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingCerere", false);
      });
  },
  updateWPCerere(context, id) {
    let url = process.env.VUE_APP_WP_API_HOST;
    url += "orders/" + id;
    let base64encodedData = new Buffer(
      process.env.VUE_APP_WP_API_KEY + ":" + process.env.VUE_APP_WP_API_SECRET
    ).toString("base64");
    let obj = {
      status: "completed",
    };
    Vue.http
      .put(url, obj, {
        headers: {
          Authorization: "Basic " + base64encodedData,
          "Content-Type": "application/json",
        },
      })
      .then(function () {
        console.log("Cerere status updated");
      })
      .catch(function (error) {
        console.log(error.body.message);
        console.log("Error: " + error.status + " " + error.statusText);
        // commit('setLoading', false)
      });
  },
  fetchWPOrderReports({ commit }, payload) {
    commit("setLoadingReports", true);
    let url = process.env.VUE_APP_WP_API_HOST;
    url += `reports/sales?date_min=${payload.fromDate}&date_max=${payload.toDate}`;
    let base64encodedData = new Buffer(
      process.env.VUE_APP_WP_API_KEY + ":" + process.env.VUE_APP_WP_API_SECRET
    ).toString("base64");
    Vue.http
      .get(url, {
        headers: {
          Authorization: "Basic " + base64encodedData,
        },
      })
      .then(function (response) {
        // console.log(response)
        commit("loadWPOrderReports", response.body);
        commit("setLoadingReports", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingReports", false);
      });
  },
  //
  // **** Oferte ****
  //
  fetchOferte({ commit, getters }, payload) {
    commit("setLoadingOferte", true);
    const urlParams = {
      from_date: payload.fromDate,
      to_date: payload.toDate,
    };
    let user = getters.loggedUser;
    let url = "";
    if (["admin"].includes(user.rol)) {
      url = process.env.VUE_APP_HUB_API_HOST + "api/oferte/month";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST + "api/oferte/month/" + user.id_user;
    }
    Vue.http
      .get(url, {
        params: urlParams,
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferte", response.body);
        commit("setOffersSearch", false);
        commit("setLoadingOferte", false);
      })
      .catch(function (error) {
        console.log(error);
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingOferte", false);
      });
  },
  fetchOffersBySearchTerm({ commit, getters }, searchTerm) {
    commit("setLoadingOferte", true);
    let user = getters.loggedUser;

    let url = "";
    if (["admin"].includes(user.rol)) {
      url = process.env.VUE_APP_HUB_API_HOST + "api/oferte/search/";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/oferte/" +
        user.id_user +
        "/search/";
    }

    Vue.http
      .get(url + searchTerm, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferte", response.body);
        commit("setOffersSearch", true);
        commit("setLoadingOferte", false);
      })
      .catch(function (error) {
        console.error(error);
        commit("setLoadingOferte", false);
      });
  },
  fetchOferta({ commit }, id) {
    commit("setLoadingOferta", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/oferta/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
        } else {
          commit("loadOferta", response.body);
        }
        commit("setLoadingOferta", false);
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.log("Error: " + error.body.message);
        commit("setLoading", false);
      });
  },
  duplicateOferta({ commit }, payload) {
    // console.log(payload)
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/oferta/duplicate",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("addNewOferta", response.body);
        router.push({
          path: "/oferte",
          query: M.toast({
            html: "Oferta adaugata",
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "bg-red-500",
          }),
        });
        console.error(error);
      });
  },
  async addOferta({ commit, dispatch }, newOferta) {
    await Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/oferta/add", newOferta, {
        headers: headers,
      })
      .then(function (response) {
        if (newOferta.id_cerere !== 0) {
          dispatch("updateWPCerere", newOferta.id_cerere);
        }
        // console.log(response)
        commit("loadTempCerere", {});
        commit("addNewOferta", response.body);
        router.push({
          path: "/oferte",
          query: M.toast({
            html: "Oferta adaugata",
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "bg-red-500",
          }),
        });
        console.error("Error: " + error.body);
      });
    return true;
  },
  updOferta(context, payload) {
    // console.log(payload)
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/oferta/update/" + payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: "/oferte/single/" + payload.id,
          query: M.toast({
            html: "Oferta actualizata!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  deleteOferta({ commit }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/oferta/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("deleteOferta", id);
        router.push({
          path: "/oferte",
          query: M.toast({
            html: response.body,
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "bg-red-500",
          }),
        });
        console.error("Error: " + error.body);
      });
  },
  updOfertaStatus({ commit }, payload) {
    Vue.http
      .put(process.env.VUE_APP_HUB_API_HOST + "api/oferta/status", payload, {
        headers: headers,
      })
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("updOfertaStatus", payload);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
      });
  },
  updOfertaStatusFactAvans(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/oferta/status_factura_avans",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Statusul nu a fost trimis!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Status actualizat!",
            }),
          });
        }
      });
  },
  //
  // **** Contracte ****
  //
  fetchContracteProductie({ commit, getters }, payload) {
    commit("setLoadingContracte", true);
    let user = getters.loggedUser;
    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/contracte/month/" +
        payload.date1 +
        "/" +
        payload.date2;
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/contracte/month/" +
        user.id_user +
        "/" +
        payload.date1 +
        "/" +
        payload.date2;
    }

    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadContracteProductie", response.body);
        commit("setContractsSearch", false);
        commit("setLoadingContracte", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingContracte", false);
      });
  },
  fetchContracteProductieBySearchTerm({ commit, getters }, searchTerm) {
    commit("setLoadingContracte", true);
    let user = getters.loggedUser;

    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/contracte/search/";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/contracte/" +
        user.id_user +
        "/search/";
    }

    Vue.http
      .get(url + searchTerm, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadContracteProductie", response.body);
        commit("setContractsSearch", true);
        commit("setLoadingContracte", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingContracte", false);
      });
  },
  fetchContractProductie({ commit }, id) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/contract/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadContractProductie", response.body);
        commit("setLoading", false);
      });
  },
  fetchContractProductieByOferta({ commit }, id) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contract/byoferta/" +
          id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadContractProductie", response.body);
      });
  },
  addContractProductie({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/contract/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
          router.push({
            query: M.toast({
              html: "Eroare la generare!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Contract generat!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchContractProductie", payload.oferta_id);
        }
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.log("Error: " + error.body.message);
      });
  },
  updateContractContent({ commit, dispatch }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/contract/editcontent",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        dispatch("fetchContractProductie", payload.id);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
        console.error(error);
        commit("setLoading", false);
      });
  },
  deleteContractProductie({ commit, dispatch }, payload) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contract/delete/" +
          payload.id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Contractul nu poate fi stears!",
            }),
          });
        } else {
          commit("loadContractProductie", {});
          dispatch("fetchContracteProductie", payload);
          router.push({
            query: M.toast({
              html: "Contract stears!",
            }),
          });
        }
      });
  },
  updContractReviewStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contract/status-review",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("updContractStatusReview", payload);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
      });
  },
  changeContractProductieLucrareStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contracte/update-lucrare-status/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        if (payload.status == 1) {
          commit("changeContractProductieLucrareStatus", payload);
        }
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
      });
  },
  //
  // **** Acte aditionale ****
  //
  fetchActAditionalId({ commit }, contract_id) {
    commit("setLoading", true);
    console.log("121");
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contracte/aditionale/" +
          contract_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("setActAditionalValues", response.body.id_aditional);
        commit("setLoading", false);
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
        console.error(error);
        commit("setLoading", false);
      });
  },
  async fetchActAditionalExtraDetails({ commit }, contract_id) {
    commit("setLoading", true);
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contracte/aditionale/single/" +
          contract_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadActAditionalDetails", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
        console.error(error);
        commit("setLoading", false);
      });
  },
  async addActAditional({ commit, dispatch }, payload) {
    commit("setLoading", true);
    await Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contracte/aditionale/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          name: "AditionalContractProductie",
          params: {
            id: payload.contract_id,
          },
          query: M.toast({
            html: response.body,
            classes: "green",
          }),
        });
        dispatch("fetchContractProductie", payload.contract_id);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error);
        router.push({
          query: M.toast({
            html: "Eroare la generare!",
            classes: "red",
          }),
        });
        commit("setLoading", false);
      });
  },
  updateAditionalContent({ commit, dispatch }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contracte/aditionale/editcontent",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        dispatch("fetchContractProductie", payload.id_contract);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
        console.error(error);
        commit("setLoading", false);
      });
  },
  deleteActAditional(context, payload) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/contracte/aditionale/delete/" +
          payload.id_aditional,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          name: "DetaliiContractProductie",
          params: {
            id: payload.id_contract,
          },
          query: M.toast({
            html: response.body,
            classes: "grey",
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
      });
  },
  //
  // **** Contracte/Carport
  //
  async saveCarportInfoOffer({ commit }, payload) {
    commit("setLoading", true);
    // commit('updateContractWCarportInfo', { contract_id: payload.contract_id, carport_info: payload.carport});
    // commit("setLoading", false);
    const obj = {
      offer_id: payload.offer_id,
      carport: JSON.stringify(payload.carport),
    };
    await Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/update_carport_status",
        obj,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("updateContractWCarportInfo", {
          contract_id: payload.contract_id,
          carport_info: payload.carport,
        });
        commit("setLoading", false);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "green",
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
        console.error(error);
        commit("setLoading", false);
      });
  },
  //
  // **** Proforme ****
  //
  fetchProforme({ commit, getters }, payload) {
    commit("setLoadingProforme", true);
    let user = getters.loggedUser;
    let url = "";
    if (["admin"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/proforme/month/" +
        payload.date1 +
        "/" +
        payload.date2;
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/proforme/month/" +
        user.id_user +
        "/" +
        payload.date1 +
        "/" +
        payload.date2;
    }

    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProforme", response.body);
        commit("setProformeSearch", false);
        commit("setLoadingProforme", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingProforme", false);
      });
  },
  fetchProformeBySearchTerm({ commit, getters }, searchTerm) {
    commit("setLoadingProforme", true);
    let user = getters.loggedUser;

    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/proforme/search/";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/proforme/" +
        user.id_user +
        "/search/";
    }

    Vue.http
      .get(url + searchTerm, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProforme", response.body);
        commit("setProformeSearch", true);
        commit("setLoadingProforme", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingProforme", false);
      });
  },
  fetchProformeProductie({ commit, getters }, payload) {
    commit("setLoadingProformeProductie", true);
    let user = getters.loggedUser;
    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/proforme/month/" +
        payload.date1 +
        "/" +
        payload.date2;
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/proforme/month/" +
        user.id_user +
        "/" +
        payload.date1 +
        "/" +
        payload.date2;
    }

    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProformeProductie", response.body);
        commit("setProformeProductieSearch", false);
        commit("setLoadingProformeProductie", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingProformeProductie", false);
      });
  },
  fetchProformeProductieBySearchTerm({ commit, getters }, searchTerm) {
    commit("setLoadingProformeProductie", true);
    let user = getters.loggedUser;

    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/proforme/search/";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/proforme/" +
        user.id_user +
        "/search/";
    }

    Vue.http
      .get(url + searchTerm, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProformeProductie", response.body);
        commit("setProformeProductieSearch", true);
        commit("setLoadingProformeProductie", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingProformeProductie", false);
      });
  },
  fetchProforma({ commit }, oferta_id) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/proforma/" + oferta_id, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProforma", response.body);
      });
  },
  fetchProformaProductie({ commit }, oferta_id) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/proforma/" +
          oferta_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadProformaProductie", response.body);
      });
  },
  addProforma({ dispatch }, payload) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/proforma/add", payload, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la generare!",
              classes: "red",
            }),
          });
          router.push({
            query: M.toast({
              html: "Eroare la generare!",
              classes: "red",
            }),
          });
          M.Modal.getInstance(
            document.querySelector(".modal-proforma")
          ).close();
        } else {
          router.push({
            query: M.toast({
              html: "Proforma generata!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchProforma", payload.oferta_id);
        }
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.log("Error: " + error.body.message);
      });
  },
  addProformaProductie({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/proforma/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la generare!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Proforma generata!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchProformaProductie", payload.oferta_id);
        }
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.log("Error: " + error.body.message);
      });
  },
  updProforma({ dispatch }, payload) {
    Vue.http
      .put(process.env.VUE_APP_HUB_API_HOST + "api/proforma/update", payload, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la actualizare!",
              classes: "red",
            }),
          });
          M.Modal.getInstance(
            document.querySelector(".modal-proforma")
          ).close();
        } else {
          router.push({
            query: M.toast({
              html: "Proforma actualizata!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchProforma", payload.oferta_id);
        }
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.log("Error: " + error.body.message);
      });
  },
  updProformaProductie({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/proforma/update",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la actualizare!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Proforma actualizata!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchProformaProductie", payload.oferta_id);
        }
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.log("Error: " + error);
      });
  },
  updateProformaPaidStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/proforma/update-paid-status",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("setProformaPaidStatus", payload);
        router.push({
          query: M.toast({
            html: `Proforma ${payload.id} marcata ca ${response.bodyText}`,
            classes: `${
              payload.paid_status == "incasata"
                ? "bg-green-500"
                : payload.paid_status == "platita"
                ? "bg-blue-400"
                : ""
            }`,
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.error("Error: " + error.bodyText);
      });
  },
  updateProformaProductiePaidStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/proforma/update-paid-status",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("setProformaProductiePaidStatus", payload);
        router.push({
          query: M.toast({
            html: `Proforma ${payload.id} marcata ca ${response.bodyText}`,
            classes: `${
              payload.paid_status == "incasata"
                ? "bg-green-500"
                : payload.paid_status == "platita"
                ? "bg-blue-400"
                : ""
            }`,
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.error("Error: " + error.bodyText);
      });
  },
  updateProformaGeneralStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/proforma/update-general-status",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("setProformaGeneralStatus", payload);
        router.push({
          query: M.toast({
            html: `${response.body}`,
            classes: "green",
          }),
        });
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
            classes: "red",
          }),
        });
        console.error("Error: " + error.bodyText);
      });
  },
  deleteProforma({ dispatch }, payload) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/proforma/delete/" + payload.id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Proforma nu poate fi stearsa!",
            }),
          });
        } else {
          dispatch("fetchProforme", payload);
          router.push({
            query: M.toast({
              html: "Proforma stearsa!",
            }),
          });
        }
      });
  },
  deleteProformaProductie({ commit, dispatch }, payload) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/proforma/delete/" +
          payload.id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Proforma nu poate fi stearsa!",
            }),
          });
        } else {
          commit("loadProformaProductie", {});
          dispatch("fetchProformeProductie", payload);
          router.push({
            query: M.toast({
              html: "Proforma stearsa!",
            }),
          });
        }
      });
  },
  //
  // **** Comenzi ****
  //
  fetchComenziActive({ commit, getters }, payload) {
    commit("setLoading", true);
    let user = getters.loggedUser;
    let url = "";

    if (["admin"].includes(user.rol)) {
      url = process.env.VUE_APP_HUB_API_HOST + "api/comenzi/active";
      if (payload) {
        url += "/" + payload.date1 + "/" + payload.date2;
      }
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST + "api/comenzi/active/" + user.id_user;
      if (payload) {
        url += "/" + payload.date1 + "/" + payload.date2;
      }
    }
    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadComenziActive", response.body);
        commit("setLoading", false);
      });
  },
  fetchComenziFinalizate({ commit, getters }, payload) {
    commit("setLoading", true);
    let user = getters.loggedUser;
    let url = "";

    if (["admin"].includes(user.rol)) {
      url = process.env.VUE_APP_HUB_API_HOST + "api/comenzi/finalizate";
      if (payload) {
        url += "/" + payload.date1 + "/" + payload.date2;
      }
    } else {
      url = process.env.VUE_APP_HUB_API_HOST + "api/comenzi/finalizate";
      if (payload) {
        url += "/" + user.id_user + "/" + payload.date1 + "/" + payload.date2;
      }
    }

    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadComenziFinalizate", response.body);
        commit("setLoading", false);
      });
  },
  // fetchComenziActive({commit, getters}) {
  //   let user = getters.loggedUser;
  //   let url = ''
  //   if (user.rol === 'admin') {
  //     url = process.env.VUE_APP_HUB_API_HOST + 'api/comenzi/active'
  //   } else if (user.rol === 'partener') {
  //     url = process.env.VUE_APP_HUB_API_HOST + 'api/comenzi/active/firma/'+user.firma
  //   } else {
  //     url = process.env.VUE_APP_HUB_API_HOST + 'api/comenzi/active/'+user.id_user
  //   }
  //   Vue.http.get(url)
  //     .then(function(response){
  //       commit('loadComenziActive', response.body)
  //     });
  // },
  // fetchComenziFinalizate({commit, getters}) {
  //   let user = getters.loggedUser;
  //   let url = ''
  //   if (user.rol === 'admin') {
  //     url = process.env.VUE_APP_HUB_API_HOST + 'api/comenzi/finalizate'
  //   } else if (user.rol === 'partener') {
  //     url = process.env.VUE_APP_HUB_API_HOST + 'api/comenzi/finalizate/firma/'+user.firma
  //   } else {
  //     url = process.env.VUE_APP_HUB_API_HOST + 'api/comenzi/finalizate/'+user.id_user
  //   }
  //   Vue.http.get(url)
  //     .then(function(response){
  //       commit('loadComenziFinalizate', response.body)
  //     });
  // },
  fetchComenziByUser({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comenzi/all/" +
          payload.date1 +
          "/" +
          payload.date2 +
          "/" +
          payload.user_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadAllUserComenzi", response.body);
      });
  },
  fetchAllCurrentComenziByUser({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comenzi/all/" +
          payload.date1 +
          "/" +
          payload.date2 +
          "/" +
          payload.user_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadAllUserCurrentComenzi", response.body);
      });
  },
  fetchComenziBonus({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comenzi/bonus/" +
          payload.date1 +
          "/" +
          payload.date2 +
          "/" +
          payload.user_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadAllBonusComenzi", response.body);
      });
  },
  fetchComanda({ commit }, id) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/comanda/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadComanda", response.body);
      });
  },
  addComanda(context, newComanda) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/comanda/add", newComanda, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            path: "/comenzi",
            query: M.toast({
              html: "Comanda adaugata!",
              classes: "bg-green-500",
            }),
          });
        }
      })
      .catch(function (error) {
        router.push({
          query: M.toast({
            html: "Eroare!!!",
          }),
        });
        console.log("Error: " + error.body.message);
      });
  },
  updComanda(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/single/" +
          payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: "/comenzi",
          query: M.toast({
            html: "Comanda actualizata!",
          }),
        });
        //dispatch('fetchComenziActive');
      });
  },
  updComandaStatus(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/status/" +
          payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Statusul nu a fost trimis!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Status trimis!",
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  updAdresaLivrare(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/adresa_livrare/" +
          payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Adresa nu a fost salvata!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Adresa actualizata!",
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  changeOrderedStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/marfa_comandata/" +
          payload.id,
        { marfa_comandata: payload.marfa_comandata },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Eroare! Modificari nesalvate!",
              classes: "red darken-1",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Comanda actualizata!",
              classes: "bg-green-500",
            }),
          });
          commit("changeOrderedStatus", payload);
        }
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  saveProblemaComanda({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/problema/" +
          payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Eroare! Modificari nesalvate!",
              classes: "red darken-1",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Comanda actualizata!",
              classes: "bg-green-500",
            }),
          });
          commit("updateProblemaStatus", payload);
        }
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  finalizeazaComanda({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/finalizeaza/" +
          payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Eroare! Modificari nesalvate!",
              classes: "red darken-1",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Comanda actualizata!",
              classes: "bg-green-500",
            }),
          });
          commit("changeStareComanda", { id: payload.id, stare: "finalizata" });
        }
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  deleteComanda({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/comanda/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Comanda nu poate fi stearsa!",
            }),
          });
        } else {
          dispatch("fetchComenziActive");
          router.push({
            path: "/comenzi",
            query: M.toast({
              html: "Comanda stearsa!",
            }),
          });
        }
      });
  },
  //
  // **** File uploads ****
  //
  submitFile({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/fileupload/add",
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: process.env.VUE_APP_HUB_API_KEY,
          },
          params: {
            cc_id: payload.cc_id,
            tip_doc: payload.tip_doc,
          },
        }
      )
      .then(function (response) {
        if (response.body.error) {
          alert(response.bodyText);
          //
        } else {
          router.push({
            query: M.toast({
              html: response.bodyText,
            }),
          });
          dispatch("updateStateDoc", {
            tip_doc: payload.tip_doc,
            cc_id: payload.cc_id,
            comanda_id: payload.comanda_id,
            activ: 1,
          });
        }
      })
      .catch(function (error) {
        console.log(error.body);
      });
  },
  //
  // **** fetch files ****
  //
  fetchDoc({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST + "api/filemeta",
        {
          params: {
            cc_id: payload.cc_id,
            tip: payload.tip_doc,
          },
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.name) {
          let id_doc = response.body.id_doc;
          let name = response.body.name;
          let type = response.body.type;
          Vue.http
            .get(
              process.env.VUE_APP_HUB_API_HOST + "api/fileupload",
              {
                responseType: "arraybuffer",
                params: {
                  cc_id: payload.cc_id,
                  tip: payload.tip_doc,
                },
              },
              {
                headers: headers,
              }
            )
            .then(function (response) {
              let blob = new Blob([response.body], {
                type: type,
              });
              let docURL = URL.createObjectURL(blob);
              let meta = {
                id: id_doc,
                filename: name,
                doctype: type,
              };
              if (payload.tip_doc === "contract") {
                commit("loadContract", docURL);
                commit("loadContractMeta", meta);
              } else if (payload.tip_doc === "factura") {
                commit("loadFactura", docURL);
                commit("loadFacturaMeta", meta);
              } else if (payload.tip_doc === "awb") {
                commit("loadAwb", docURL);
                commit("loadAwbMeta", meta);
              } else {
                alert("Error on file load!");
              }
            });
        }
      });
  },
  checkDoc({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/files/update_doc_check/" +
          payload.cc_id,
        {
          tip: payload.tip_doc,
          activ: payload.activ,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          dispatch("fetchComanda", payload.comanda_id);
        }
      });
  },
  updateStateDoc({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/files/update_doc_state/" +
          payload.cc_id,
        {
          tip: payload.tip_doc,
          activ: payload.activ,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          dispatch("fetchComanda", payload.comanda_id);
        }
      });
  },
  addPayment({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/payment/" +
          payload.comanda_id,
        {
          tip: payload.tip_plata,
          data: payload.data_plata,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          dispatch("fetchComanda", payload.comanda_id);
        }
      });
  },
  addComandaBonus(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/update/bonus/" +
          payload.comanda_id,
        {
          tip: payload.tip_plata,
          bonus: payload.bonus,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        }
      });
  },
  addBonus({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/bonus/add",
        {
          data_bonus: payload.data_bonus,
          total_vanzari: payload.total_vanzari,
          total_lucrari: payload.total_lucrari,
          calculat: payload.calculat,
          user_id: payload.user_id,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          //dispatch('fetchComenziByUser', { date1: payload.date1, date2: payload.date2, user_id: payload.user_id} )
          dispatch("fetchBonus", payload.date1);
          router.push({
            query: M.toast({
              html: "Bonus calculat!",
            }),
          });
        }
      });
  },
  fetchBonus({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/bonus/" +
          payload.data +
          "/" +
          payload.user_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadBonus", response.body);
      });
  },
  deletePayment({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/comanda/delete_payment/" +
          payload.comanda_id,
        {
          tip: payload.tip_plata,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          dispatch("fetchComanda", payload.comanda_id);
        }
      });
  },
  deleteDoc({ dispatch }, payload) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/files/delete/" + payload.id_doc,
        {
          params: {
            tip: payload.tip_doc,
          },
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Fisierul nu poate fi stears!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: response.body.notice.text,
            }),
          });
          dispatch("updateStateDoc", {
            tip_doc: payload.tip_doc,
            cc_id: payload.cc_id,
            comanda_id: payload.comanda_id,
            activ: 0,
          });
          dispatch("checkDoc", {
            tip_doc: payload.tip_doc,
            cc_id: payload.cc_id,
            comanda_id: payload.comanda_id,
            activ: 0,
          });
        }
      });
  },
  //
  // **** Currency ****
  //
  fetchCurrency({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/currency", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadCurrency", response.body);
      });
  },
  //
  // **** Company details ****
  //
  async fetchCompanyDetailsFromAnaf({ commit }, cui) {
    commit("setLoadingCompanyDetails", true);
    const urlParams = {
      cui: cui,
    };
    let companyDetails = null;
    await Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/anaf/company", {
        headers: headers,
        params: urlParams,
      })
      .then(function (response) {
        companyDetails = response.body.found[0];
        commit("setLoadingCompanyDetails", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoadingCompanyDetails", false);
      });
    return companyDetails;
  },
  //
  // **** Clienti ****
  //
  async checkDuplicateClient({ commit }, payload) {
    commit("setLoading", true);
    const urlParams = {
      firma: payload.firma,
      cui: payload.cui,
      nume: payload.nume,
      tel: payload.tel,
      email: payload.email,
    };
    let duplicates = false;

    await Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/clienti/check-client", {
        headers: headers,
        params: urlParams,
      })
      .then(function (response) {
        const results = response.body;
        if (results.length > 0) {
          duplicates = true;
        }
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        alert("Eroare!");
        commit("setLoading", false);
      });
    return duplicates;
  },
  fetchClientiCount({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/clienti/count", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadClientiCount", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  fetchClienti({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/clienti/select", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadClienti", response.body);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
      });
  },
  fetchClientiData({ commit }, page) {
    commit("setLoadingClienti", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/clienti/page/" + page, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadClientiData", response.body);
        commit("setLoadingClienti", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingClienti", false);
      });
  },
  fetchClientiDataBySearchTerm({ commit }, payload) {
    commit("setLoadingClienti", true);
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/clienti/search/" +
          payload.searchType +
          "/" +
          payload.searchTerm,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadClientiCount", response.body.length);
        commit("loadClientiData", response.body);
        commit("setLoadingClienti", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingClienti", false);
      });
  },
  fetchClient({ commit }, id) {
    commit("setLoadingClient", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/client/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadClient", response.body);
        commit("setLoadingClient", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingClient", false);
      });
  },
  addClient({ commit, dispatch }, newClient) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/client/add", newClient, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
        } else {
          commit("loadReturnClientId", response.body.id);
          router.push({
            query: M.toast({
              html: response.body.message,
              classes: "bg-green-500",
            }),
          });
          commit("addNewClient", newClient);
          dispatch("fetchClienti");
          dispatch("fetchClientiData", 1);
        }
      });
  },
  updClient({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/client/update/" + payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function () {
        dispatch("fetchClient", payload.id);
        router.push({
          query: M.toast({
            html: "Client actualizat!",
            classes: "green",
          }),
        });
      })
      .catch(function (error) {
        console.error(error);
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
      });
  },
  addCCMail({ commit, getters }, payload) {
    let ccMails = [];
    getters.oferta.cc_obj.forEach((mail) => {
      ccMails.push(mail);
    });
    ccMails.push(payload.mail);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/client/update/cc/" +
          payload.id_client,
        {
          cc_obj: JSON.stringify(ccMails),
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
          router.push({
            query: M.toast({
              html: "Emailul nu a fost adaugat!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Email adaugat!",
            }),
          });
          commit("loadCCMail", payload.mail);
        }
      });
  },
  deleteClient({ commit }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/client/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("deleteClient", id);
        router.push({
          path: "/clienti",
          query: M.toast({
            html: response.body,
          }),
        });
      })
      .catch((err) => {
        router.push({
          query: M.toast({
            html: "Clientul nu poate fi sters",
            classes: "bg-red-500",
          }),
        });
        console.error(err);
      });
  },
  //
  // **** Users ****
  //
  fetchUser({ commit }, mail) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/user/" + mail, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadUser", response.body);
        commit("userLoaded", true);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoading", false);
      });
  },
  fetchUsers({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/users", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadUsers", response.body);
        commit("setLoading", false);
      });
  },
  //
  // **** Misc ****
  //
  fetchStocScrap({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/stocscrap", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadStocScrap", response.body);
      });
  },
  updateStocScrap({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/stocscrap/update/" + payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Numarul de " + payload.denumire + " nu poate fi actualizat!");
          router.push({
            query: M.toast({
              html: "Numarul de " + payload.denumire + " nu a fost actualizat",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Numarul de " + payload.denumire + " actualizat!",
            }),
          });
          if (payload.denumire == "pachete") {
            commit("updatePachete", payload.buc);
          } else if (payload.denumire == "saci") {
            commit("updateSaci", payload.buc);
          } else if (payload.denumire == "span") {
            commit("updateSpan", payload.buc);
          }
        }
      });
  },
  fetchMateriale({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/materiale", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadMateriale", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoading", false);
      });
  },
  fetchListaProduse({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/produse", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadListaProduse", response.body);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
      });
  },
  fetchAliaje({ commit }) {
    commit("setLoadingAliaje", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/aliaje", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadAliaje", response.body);
        commit("setLoadingAliaje", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoadingAliaje", false);
      });
  },
  fetchConsumabile({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/consumabile", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadConsumabile", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoading", false);
      });
  },
  fetchManopera({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/manopera", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadManopera", response.body);
        commit("setLoading", false);
      });
  },
  fetchPreturiVopsit({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/preturi-vopsit", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadPreturiVopsit", response.body);
        commit("setLoading", false);
      });
  },
  fetchPreturiServ({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/preturi-serv", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadPreturiServ", response.body);
        commit("setLoading", false);
      });
  },
  fetchFurnizori({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/furnizori", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadFurnizori", response.body);
        commit("setLoading", false);
      });
  },
  //
  // **** Oferte Productie ****
  //
  fetchOferteProductieGarduri({ commit, getters }, payload) {
    commit("setLoadingOferteGarduri", true);
    const urlParams = {
      from_date: payload.fromDate,
      to_date: payload.toDate,
    };
    let user = getters.loggedUser;
    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/garduri/month";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/oferte/garduri/month/" +
        user.id_user;
    }

    Vue.http
      .get(url, {
        params: urlParams,
        headers: headers,
      })
      .then((response) => {
        commit("loadOferteProductieGarduri", response.body);
        commit("setFenceOffersSearch", false);
        commit("setLoadingOferteGarduri", false);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchFenceOffersBySearchTerm({ commit, getters }, searchTerm) {
    commit("setLoadingOferteGarduri", true);
    let user = getters.loggedUser;

    let url = "";
    if (["admin", "superuser"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/oferte/garduri/search/";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/oferte/garduri/" +
        user.id_user +
        "/search/";
    }

    Vue.http
      .get(url + searchTerm, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferteProductieGarduri", response.body);
        commit("setFenceOffersSearch", true);
        commit("setLoadingOferteGarduri", false);
      })
      .catch(function (error) {
        console.error("Error: " + error.status + " " + error.statusText);
        commit("setLoadingOferteGarduri", false);
      });
  },
  fetchOferteProductieGeneral({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/general", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferteProductieGeneral", response.body);
        commit("setLoading", false);
      });
  },
  fetchOferteProductieConfiguratorGard({ commit, getters }) {
    commit("setLoadingOferteConfigurator", true);
    let url =
      process.env.VUE_APP_HUB_API_HOST +
      "api/productie/oferte/configurator-gard";
    let user = getters.loggedUser;
    if (user.rol === "user_productie") {
      url += "/zone/" + user.zona;
    }
    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferteProductieConfiguratorGard", response.body);
        commit("setLoadingOferteConfigurator", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  fetchListaReveniriConfiguratorGardCounts({ commit, getters }) {
    commit("setLoadingListaReveniriConfigurator", true);
    let user = getters.loggedUser;
    const url =
      process.env.VUE_APP_HUB_API_HOST +
      "api/productie/oferte/configurator-gard/lista-reveniri/count/user/" +
      user.id_user;

    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadListaReveniriConfiguratorGardCounts", response.body);
        commit("setLoadingListaReveniriConfigurator", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  fetchListaReveniriConfiguratorGard({ commit, getters }) {
    commit("setLoadingListaReveniriConfigurator", true);
    let user = getters.loggedUser;

    let url = "";
    if (["admin"].includes(user.rol)) {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/oferte/configurator-gard/lista-reveniri";
    } else {
      url =
        process.env.VUE_APP_HUB_API_HOST +
        "api/productie/oferte/configurator-gard/lista-reveniri/user/" +
        user.id_user;
    }

    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadListaReveniriConfiguratorGard", response.body);
        commit("setLoadingListaReveniriConfigurator", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  updateReturnItemDate({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/lista-reveniri/update_return_date",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("updateReturnDateOfertaProductieConfiguratorGard", payload);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  updateReturnItemStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/lista-reveniri/update_return_status",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("updateReturnStatusOfertaProductieConfiguratorGard", payload);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  deleteReturnItem({ commit }, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/lista-reveniri/delete/" +
          id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        commit("deleteReturnItemProductieConfiguratorGard", id);
        router.push({
          query: M.toast({
            html: response.body,
          }),
        });
      })
      .catch((error) => {
        console.log(error);
        router.push({
          query: M.toast({
            html: "Eroare",
            classes: "bg-red-500",
          }),
        });
      });
  },
  fetchOferteProductieConfiguratorGardForRange({ commit }, payload) {
    let url =
      process.env.VUE_APP_HUB_API_HOST +
      "api/productie/oferte/configurator-gard/range/";
    commit("setLoadingOferteConfigurator", true);
    Vue.http
      .get(url + payload.date1 + "/" + payload.date2, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferteProductieConfiguratorGard", response.body);
        commit("setLoadingOferteConfigurator", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  fetchOferteProductieConfiguratorEU({ commit }) {
    let url =
      process.env.VUE_APP_HUB_API_HOST +
      "api/productie/oferte/configurator-gard/eu";
    commit("setLoading", true);
    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOferteProductieConfiguratorEU", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  fetchNextOferteProductieConfiguratorGard({ commit, getters }, id) {
    commit("setLoadingOferteConfigurator", true);
    let url =
      process.env.VUE_APP_HUB_API_HOST +
      "api/productie/oferte/configurator-gard/next/" +
      id;
    let user = getters.loggedUser;
    if (user.rol === "user_productie") {
      url += "/zone/" + user.zona;
    }
    Vue.http
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadNextOferteProductieConfiguratorGard", response.body);
        commit("setLoadingOferteConfigurator", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  updateInteresStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/update_interes_status",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("updateInteresStatusOfertaProductieConfiguratorGard", payload);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  markCalledCerereGard({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/markcalled",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        commit("markCalledOfertaProductieConfiguratorGard", payload);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch((error) => {
        console.log(error);
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
      });
  },
  markSentOfertaCerereGard({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/marksentoferta",
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        commit("markSentOfertaOfertaProductieConfiguratorGard", payload);
        router.push({
          query: M.toast({
            html: "Status oferta actualizat!",
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
      });
  },
  fetchOferteProductieByUser({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/all/" +
          payload.date1 +
          "/" +
          payload.date2 +
          "/" +
          payload.user_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadAllUserLucrari", response.body);
      });
  },
  fetchOferteProductieBonus({ commit }, payload) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/bonus/" +
          payload.date1 +
          "/" +
          payload.date2 +
          "/" +
          payload.user_id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadAllBonusLucrari", response.body);
      });
  },
  fetchOfertaProductie({ commit }, id) {
    commit("setLoadingOfertaGarduri", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadCurrentOfertaProductie", response.body);
        commit("setLoadingOfertaGarduri", false);
      });
  },
  duplicateOfertaProductie(context, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/duplicate",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Eroare la adaugarea ofertei!");
          router.push({
            query: M.toast({
              html: "Oferta nu a fost adaugata!",
            }),
          });
        } else {
          router.push({
            path: "/oferte-productie",
            query: M.toast({
              html: "Oferta productie adaugata!",
            }),
          });
        }
      });
  },
  addOfertaProductie(context, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: "/oferte-productie",
          query: M.toast({
            html: "Oferta productie adaugata!",
          }),
        });
      });
  },
  deleteOfertaProductie(context, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/delete/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Oferta nu poate fi stersa!",
              classes: "red darken-1",
            }),
          });
        } else {
          router.push({
            path: "/oferte-productie",
            query: M.toast({
              html: "Oferta stersa!",
            }),
          });
        }
      });
  },
  addPaymentLucrare({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/update_payment/" +
          payload.lucrare_id,
        {
          tip: payload.tip_plata,
          data: payload.data_plata,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          dispatch("fetchOfertaProductie", payload.lucrare_id);
        }
      });
  },
  addLucrareBonus(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/update_bonus/" +
          payload.lucrare_id,
        {
          tip: payload.tip_plata,
          bonus: payload.bonus,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        }
      });
  },
  changeNameOfertaProductie({ dispatch }, payload) {
    // console.log('Id: '+payload.id+' - Obejct: '+payload.object);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updatename/" +
          payload.id,
        payload.object,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: "/oferte-productie/single/" + payload.id,
          query: M.toast({
            html: "Nume lucrare actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchOfertaProductie", payload.id);
      });
  },
  changeBucOfertaProductie({ dispatch }, payload) {
    //console.log('Id: '+payload.id+' - Buc: '+payload.buc);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updatebuc/" +
          payload.id,
        {
          buc: payload.buc,
        },
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: "/oferte-productie/single/" + payload.id,
          query: M.toast({
            html: "Nr buc actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchOfertaProductie", payload.id);
      });
  },
  changeColorOfertaProductie({ dispatch }, payload) {
    //console.log('Id: '+payload.id+' - Buc: '+payload.buc);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updatecolor/" +
          payload.id,
        {
          color: payload.color,
        },
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          query: M.toast({
            html: "Culoare actualizata!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchOfertaProductie", payload.id);
      });
  },
  changeAvansOfertaProductie({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updateavans/" +
          payload.id,
        {
          avans: payload.avans,
        },
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          query: M.toast({
            html: "Avans actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchOfertaProductie", payload.id);
      });
  },

  changeStatusMontajInclus({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updatestatusmontaj/" +
          payload.id,
        {
          montaj_inclus: payload.montaj_inclus,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("updateCurrentOfertaProductieStatusMontaj", {
          montaj_inclus: payload.montaj_inclus,
        });
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        router.push({
          query: M.toast({
            html: "Eroare la salvare!",
            classes: "red",
          }),
        });
        commit("setLoading", false);
      });
  },
  changeExecutieOfertaProductie({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/executie/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("updateCurrentOfertaProductieExecutie", payload);
      })
      .catch((err) => {
        router.push({
          query: M.toast({
            html: "Eroare la salvare",
            classes: "bg-red-500",
          }),
        });
        console.error(err);
      });
  },
  changeDetaliiPlataOfertaProductie({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updatedetaliiplata/" +
          payload.id,
        {
          c_plata: payload.c_plata,
          c_plata2: payload.c_plata2,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("updateCurrentOfertaProductieDetaliiPlata", {
          c_plata: payload.c_plata,
          c_plata2: payload.c_plata2,
        });
      })
      .catch((err) => {
        router.push({
          query: M.toast({
            html: "Eroare la salvare",
            classes: "bg-red-500",
          }),
        });
        console.error(err);
      });
  },
  changeDetaliiOfertaProductie({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/updatedetalii/" +
          payload.id,
        {
          detalii: payload.detalii,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("updateCurrentOfertaProductieDetalii", payload.detalii);
      })
      .catch((err) => {
        router.push({
          query: M.toast({
            html: "Eroare la salvare",
            classes: "bg-red-500",
          }),
        });
        console.error(err);
      });
  },
  saveMateriePrima(context, payload) {
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/materieprima/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: path + payload.id,
          query: M.toast({
            html: "Materie prima actualizata!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  saveManopera(context, payload) {
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/manopera/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: path + payload.id,
          query: M.toast({
            html: "Manopera actualizata!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  saveVopsit(context, payload) {
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/vopsit/" + payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: path + payload.id,
          query: M.toast({
            html: "Vopsit actualizat!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  saveServicii(context, payload) {
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/servicii/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: path + payload.id,
          query: M.toast({
            html: "Servicii actualizate!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  saveConsumabile(context, payload) {
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/consumabile/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: path + payload.id,
          query: M.toast({
            html: "Consumabile actualizate!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  saveMateriale(context, payload) {
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/materiale/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          path: path + payload.id,
          query: M.toast({
            html: "Alte materiale actualizate!",
            classes: "bg-green-500",
          }),
        });
      });
  },
  saveModuleGard(context, payload) {
    // console.log(payload)
    let path =
      payload.tip == "oferta"
        ? "/oferte-productie/single/"
        : "/lucrari-productie/single/";
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/garduri/" +
          payload.id,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Eroare la actualizare!",
              classes: "red",
            }),
          });
          console.error(response.body.error);
        } else {
          router.push({
            path: path + payload.id,
            query: M.toast({
              html: "Gard actualizat!",
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  updOfertaProductieStatusFactAvans(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/status_factura_avans",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Statusul nu a fost trimis!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Status actualizat!",
            }),
          });
        }
      });
  },
  moveToOferteGarduri(context, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oferte/move",
        payload,
        {
          headers: headers,
        }
      )
      .then((response) => {
        router.push({
          path: "/oferte-productie",
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  //
  // *** Productie - Cereri constrcutie ***
  //
  fetchCereriConstructie({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/boltari",
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadCereriConstructieConfiguratorGard", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error);
        commit("setLoading", false);
      });
  },
  markCalledCerereBoltari({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/boltari/markcalled",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
          // console.error('Error:'+response.body.error);
        } else {
          commit("markCalledOfertaProductieBoltariConfiguratorGard", payload);
          router.push({
            query: M.toast({
              html: response.body,
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  updateBricksInteresStatus({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/oferte/configurator-gard/boltari/update_interes_status",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("updateInteresStatusCerereBricksConfiguratorGard", payload);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  //
  // **** Lucrari ****
  //

  updateLucrareEchipa({ commit }, payload) {
    M.Modal.getInstance(document.querySelector(".modal-oameni")).close();
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/lucrari/echipa/update/" +
          payload.id,
        {
          echipa: JSON.stringify(payload.echipa),
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la actualizare!",
              classes: "red",
            }),
          });
        } else {
          commit("updateLucrareEchipa", payload.echipa);
          router.push({
            query: M.toast({
              html: "Echipa actualizata!",
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  changeExTimeLucrareProductie({ commit }, payload) {
    //console.log('Id: '+payload.id+' - Buc: '+payload.buc);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/lucrari/updateextime/" +
          payload.id,
        {
          t_executie: payload.t_executie,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("updateExTimeLucrare", payload);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
      });
  },
  //
  // **** Calendar Lucrari ****
  //
  fetchCalendarLucrari({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/lucrari/events", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadCalendarLucrari", response.body);
      });
  },
  addCalendarLucrare({ commit }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/lucrari/events/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la salvarea programarii!",
              classes: "red darken-1",
            }),
          });
        } else {
          commit("addLucrareDates", payload);
          router.push({
            query: M.toast({
              html: "Lucrare programata!",
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  editCalendarLucrare({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/lucrari/events/edit",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la salvarea programarii!",
              classes: "red darken-1",
            }),
          });
        } else {
          commit("addLucrareDates", payload);
          router.push({
            query: M.toast({
              html: "Programare actualizata!",
              classes: "bg-green-500",
            }),
          });
        }
      });
  },
  deleteCalendarLucrare({ commit }, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/lucrari/events/delete/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Programarea nu poate fi stersa!",
            }),
          });
        } else {
          router.push({
            path: "/calendar-productie",
            query: M.toast({
              html: "Programare stersa!",
            }),
          });
          commit("deleteCalendarLucrare", id);
        }
        // M.Modal.getInstance(document.querySelector('.modal-delete')).close();
      });
  },
  //
  // **** Debitare ****
  //
  fetchProfile({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/profile", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProfile", response.body);
      });
  },
  fetchProfil({ commit }, id) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/profil/" + id, {
        headers: headers,
      })
      .then(function (response) {
        commit("loadProfil", response.body);
      });
  },
  addProfil({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/profile/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Profil adaugat!",
            }),
          });
          dispatch("fetchProfile");
        }
      });
  },
  updProfil(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/debitare/profil/update/" +
          payload.id,
        payload.obj,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Eroare la actualizare!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            path: "/debitare/profile",
            query: M.toast({
              html: "Profil actualizat!",
            }),
          });
        }
      });
  },
  deleteProfil(context, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/profile/delete/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Profilul nu poate fi sters!");
          M.Modal.getInstance(
            document.querySelector(".modal-del-profil")
          ).close();
          router.push({
            query: M.toast({
              html: "Profilul nu a fost sters!",
            }),
          });
        } else {
          M.Modal.getInstance(
            document.querySelector(".modal-del-profil")
          ).close();
          router.push({
            path: "/debitare/profile",
            query: M.toast({
              html: "Profil sters!",
            }),
          });
        }
      });
  },
  editProfilCote({ dispatch }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/debitare/profile/editcote/" +
          payload.id,
        {
          profil_cote: payload.profilCote,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          router.push({
            query: M.toast({
              html: "Cote Profil actualizate!",
            }),
          });
          dispatch("fetchProfile");
        }
      });
  },
  fetchEchipeDeb({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/echipe", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadEchipeDeb", response.body);
      });
  },
  fetchStocProfile({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/materiale", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadStocProfile", response.body);
      });
  },
  addAvizStoc(context, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/stoc/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        }
      });
  },
  fetchAvize({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/avize", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadAvize", response.body);
      });
  },
  fetchAviz({ commit }, id) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/avize/single/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadAviz", response.body);
      });
  },
  addAvizMat({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/aviz/add",
        {
          data: payload.data,
          obj_mat: JSON.stringify(payload.obj_mat),
          obj_profile: JSON.stringify(payload.obj_profile),
          obj_pachete_scrap: JSON.stringify(payload.obj_pachete_scrap),
          obj_saci_scrap: JSON.stringify(payload.obj_saci_scrap),
          obj_saci_span: JSON.stringify(payload.obj_saci_span),
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          alert("Eroare la salvare!\n" + response.bodyText);
          console.log(response.bodyText);
        } else {
          let arr_mat = [];
          let arr_profile = [];
          let arr_pachete_scrap = [];
          let arr_saci_scrap = [];
          payload.obj_mat.forEach((item) => {
            arr_mat.push(item.comanda_id);
          });
          payload.obj_profile.forEach((item) => {
            arr_profile.push(item.stoc_id);
          });
          if (payload.obj_pachete_scrap.comenzi != undefined) {
            payload.obj_pachete_scrap.comenzi.forEach((item) => {
              arr_pachete_scrap.push(item);
            });
            let nr =
              Number(payload.obj_pachete_scrap.buc) -
              Number(payload.obj_pachete_scrap.cantitate);
            dispatch("updateStocScrap", {
              id: 1,
              denumire: "pachete",
              buc: nr,
            });
          }
          if (payload.obj_saci_scrap.comenzi != undefined) {
            payload.obj_saci_scrap.comenzi.forEach((item) => {
              arr_saci_scrap.push(item);
            });
            let nr =
              Number(payload.obj_saci_scrap.buc) -
              Number(payload.obj_saci_scrap.cantitate);
            dispatch("updateStocScrap", {
              id: 2,
              denumire: "saci",
              buc: nr,
            });
          }
          if (payload.obj_saci_span.denumire_produs != undefined) {
            let nr =
              Number(payload.obj_saci_span.buc) -
              Number(payload.obj_saci_span.cantitate);
            dispatch("updateStocScrap", {
              id: 3,
              denumire: "span",
              buc: nr,
            });
          }
          dispatch("avizeazaDeb", {
            array1: arr_mat,
            array2: arr_profile,
            array3: arr_pachete_scrap,
            array4: arr_saci_scrap,
            state: 1,
          });
          router.push({
            path: "/debitare/avize",
            query: M.toast({
              html: "Aviz adaugat!",
            }),
          });
        }
      });
  },
  deleteAvizMat({ dispatch }, payload) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST +
          "api/debitare/aviz/delete/" +
          payload.id_aviz,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Avizul nu poate fi sters!");
          router.push({
            query: M.toast({
              html: "Avizul nu a fost sters!",
            }),
          });
          M.Modal.getInstance(
            document.querySelector(".modal-delete-aviz")
          ).close();
        } else {
          M.Modal.getInstance(
            document.querySelector(".modal-delete-aviz")
          ).close();
          router.push({
            path: "/debitare/avize",
            query: M.toast({
              html: "Aviz stears!",
            }),
          });
          dispatch("avizeazaDeb", {
            array1: payload.arr_mat,
            array2: payload.arr_profile,
            array3: payload.arr_pachete_scrap,
            array4: payload.arr_saci_scrap,
            state: 0,
          });
        }
      });
  },
  avizeazaDeb(context, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/avizata/update",
        {
          arr_ids1: JSON.stringify(payload.array1),
          arr_ids2: JSON.stringify(payload.array2),
          arr_ids3: JSON.stringify(payload.array3),
          arr_ids4: JSON.stringify(payload.array4),
          state: payload.state,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          alert("Eroare la salvare!\n" + response.bodyText);
          console.log(response.bodyText);
        } else {
          if (payload.state == 1) {
            router.push({
              query: M.toast({
                html: "Debitari avizate!",
              }),
            });
          } else {
            router.push({
              query: M.toast({
                html: "Debitari deavizate!",
              }),
            });
          }
        }
      });
  },
  fstateComDeb({ dispatch }, payload) {
    // console.log(payload.arr_ids)
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/debitare/comanda/update//fstate",
        {
          arr_ids: JSON.stringify(payload.arr_ids),
          status: payload.status,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          alert("Eroare la salvare!\n" + response.bodyText);
          console.error(response.body.error);
        } else {
          router.push({
            query: M.toast({
              html: "Comenzi mutate!",
            }),
          });
          if (payload.status == 1) {
            dispatch("fetchComenziDeb");
          } else {
            dispatch("fetchComenziDebFinalizate");
          }
        }
      });
  },
  updAvizAuto({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/debitare/aviz/detalii/update/" +
          payload.id,
        {
          nr_aviz: payload.nr_aviz,
          nr_auto: payload.nr_auto,
          delegat: payload.delegat,
          data: payload.data,
          ora: payload.ora,
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          alert("Eroare la salvare!");
          console.log(response.bodyText);
        } else {
          commit("updAvizDetalii", payload);
          router.push({
            query: M.toast({
              html: "Aviz actualizat!",
            }),
          });
        }
      });
  },
  fetchComenziDeb({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/comenzi", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadComenziDeb", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log("Error: " + error);
        commit("setLoading", false);
      });
  },
  fetchComenziDebFinalizate({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/comenzi/finalizate",
        {
          headers: headers,
        }
      )
      .then(function (response) {
        commit("loadComenziDebFinalizate", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoading", false);
      });
  },
  addComandaDeb(context, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/comenzi/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        }
      });
  },
  deleteComandaDeb({ dispatch }, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/debitare/comenzi/delete/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Comanda nu poate fi stersa!");
          router.push({
            query: M.toast({
              html: "Comanda nu a fost stersa!",
            }),
          });
          M.Modal.getInstance(
            document.querySelector(".modal-del-deletecomandadeb")
          ).close();
        } else {
          M.Modal.getInstance(
            document.querySelector(".modal-del-deletecomandadeb")
          ).close();
          router.push({
            query: M.toast({
              html: "Comanda stearsa!",
            }),
          });
          dispatch("fetchComenziDeb");
          dispatch("fetchDebitari");
        }
      });
  },
  fetchDebitari({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/debitari", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadDebitari", response.body);
      });
  },
  fetchListaDebitari({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/debitare/listadebitari", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadListaDebitari", response.body);
      });
  },
  fetchDebitare({ commit }, id) {
    Vue.http
      .get(
        process.env.VUE_APP_HUB_API_HOST +
          "api/debitare/listadebitare/single/" +
          id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          commit("loadDebitare", response.body);
        }
      });
  },
  addDebitare({ dispatch }, payload) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/debitare/add", payload, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
        } else {
          M.Modal.getInstance(
            document.querySelector(".modal-adddebitare")
          ).close();
          dispatch("fetchComenziDeb");
          dispatch("fetchDebitari");
        }
      });
  },
  // editDebitare({dispatch}, payload){
  //   // console.log(payload.obj);
  //   Vue.http.put(process.env.VUE_APP_HUB_API_HOST + 'api/debitare/update/'+payload.id, payload.obj)
  //   .then(function(response){
  //     if(response.bodyText.includes("error")){
  //       console.log('Error: '+response.bodyText);
  //     } else {
  //       router.push({path: '/debitare/listadebitari', query: M.toast({html: 'Debitare actualizata!'})});
  //     }
  //   });
  // },
  deleteDebitare({ commit }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/debitare/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Debitarea nu poate fi stersa!");
          router.push({
            query: M.toast({
              html: "Debitarea nu a fost stersa!",
            }),
          });
          M.Modal.getInstance(
            document.querySelector(".modal-del-debitare")
          ).close();
        } else {
          M.Modal.getInstance(
            document.querySelector(".modal-del-debitare")
          ).close();
          router.push({
            query: M.toast({
              html: "Debitare stersa!",
            }),
          });
          commit("deleteDebitare", id);
        }
      });
  },

  //
  // **** Setari ****
  //
  // Setari Materie prima
  //
  addMaterial({ dispatch }, newMaterial) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/material/add",
        newMaterial,
        {
          headers: headers,
        }
      )
      .then(function () {
        M.Modal.getInstance(
          document.querySelector(".modal-add-material")
        ).close();
        router.push({
          query: M.toast({
            html: "Material adaugat!",
          }),
        });
        dispatch("fetchMateriale");
      });
  },
  deleteMaterial({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/material/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          alert("Materialul are aliaje!");
          router.push({
            query: M.toast({
              html: "Materialul nu a fost sters!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Materialul sters!",
            }),
          });
          dispatch("fetchMateriale");
        }
        M.Modal.getInstance(
          document.querySelector(".modal-del-material")
        ).close();
      });
  },
  addProdus({ dispatch }, newProdus) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/produs/add", newProdus, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          alert("Eroare!");
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Produsul nu a fost adaugat!",
            }),
          });
        } else {
          M.Modal.getInstance(
            document.querySelector(".modal-add-produs")
          ).close();
          router.push({
            query: M.toast({
              html: "Produs adaugat!",
            }),
          });
          dispatch("fetchListaProduse");
        }
      });
  },
  updProdus({ dispatch }, updProdus) {
    Vue.http
      .put(process.env.VUE_APP_HUB_API_HOST + "api/produs/update", updProdus, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          alert("Eroare!!" + response.bodyText);
          router.push({
            query: M.toast({
              html: "Produsul nu a fost actualizat!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Produs actualizat!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchListaProduse");
        }
      });
  },
  deleteProdus({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/produs/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          alert("Produsul nu poate fi sters!");
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Produsul nu a fost sters!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Produs sters!",
            }),
          });
          dispatch("fetchListaProduse");
        }
        M.Modal.getInstance(
          document.querySelector(".modal-del-produs")
        ).close();
      });
  },
  addAliaj({ dispatch }, newAliaj) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/aliaj/add", newAliaj, {
        headers: headers,
      })
      .then(function () {
        M.Modal.getInstance(document.querySelector(".modal-add-aliaj")).close();
        router.push({
          query: M.toast({
            html: "Aliaj adaugat!",
          }),
        });
        dispatch("fetchAliaje");
      });
  },
  updAliaj({ dispatch }, updAliaj) {
    Vue.http
      .put(process.env.VUE_APP_HUB_API_HOST + "api/aliaj/update", updAliaj, {
        headers: headers,
      })
      .then(function () {
        router.push({
          query: M.toast({
            html: "Aliaj actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchAliaje");
      });
  },
  deletealiaj({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/aliaj/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          alert("Aliajul este asociat in oferte sau comenzi!");
          router.push({
            query: M.toast({
              html: "Aliajul nu a fost sters!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Aliajul sters!",
            }),
          });
          dispatch("fetchAliaje");
        }
        M.Modal.getInstance(document.querySelector(".modal-del-aliaj")).close();
      });
  },
  //
  // Setari Preturi Vanzari
  //
  fetchPreturiVanzari({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/vanzari/preturi", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadPreturiVanzari", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error.body);
        commit("setLoading", false);
      });
  },
  updPreturiVanzari({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/vanzari/preturi/update/" +
          payload.id_pret,
        {
          pret_baza: payload.pret_baza,
          lista_adaos: JSON.stringify(payload.adaos_aliaje),
        },
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        // dispatch("fetchAliaje");
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  fetchFormulaVanzari({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/vanzari/formula", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadFormulaVanzari", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  updFormulaVanzari({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/vanzari/formula/update",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("loadFormulaVanzari", payload);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        router.push({
          query: M.toast({
            html: "Eroare!",
            classes: "red",
          }),
        });
        commit("setLoading", false);
      });
  },
  //
  // Setari Preturi Materie Prima Aluminiu
  //
  // LME
  fetchLMEVanzari({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/lme/aluminium", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadLME", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error.body);
        commit("setLoading", false);
      });
  },
  updLMEVanzari({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/lme/aluminium/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("loadLME", payload);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  // Levels
  fetchLevelsVanzari({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/preturi/levels", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadLevelsVanzari", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error.body);
        commit("setLoading", false);
      });
  },
  updLevelsVanzari({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/preturi/levels/update",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        commit("loadLevelsVanzari", payload);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  // Fix Materie prima
  fetchFixedPricesMateriePrima({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/preturi/materie-prima", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadFixedPricesMateriePrima", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error.body);
        commit("setLoading", false);
      });
  },
  updateFixMateriePrima({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + `api/preturi/materie-prima/fix/add`,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        console.log(response.body);
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        // commit("updFixTable", payload);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  //
  //
  // Setari Preturi Debitare
  //
  fetchPreturiDebitare({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/vanzari/preturi_debitare", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadPreturiDebitare", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error.body);
        commit("setLoading", false);
      });
  },
  updPreturiDebitare({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/vanzari/preturi_debitare/update/" +
          payload.id_pret_debitare,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        // dispatch("fetchAliaje");
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  //
  // Setari Preturi Transport
  //
  fetchPreturiTransport({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/vanzari/preturi_transport", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadPreturiTransport", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error.body);
        commit("setLoading", false);
      });
  },
  updPreturiTransport({ commit }, payload) {
    commit("setLoading", true);
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/vanzari/preturi_transport/update/" +
          payload.id_pret_transport,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({
            html: response.body,
            classes: "bg-green-500",
          }),
        });
        // dispatch("fetchAliaje");
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body);
        commit("setLoading", false);
      });
  },
  //
  // Setari Productie
  //
  addManopera({ dispatch }, newManopera) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/manopera/add",
        newManopera,
        {
          headers: headers,
        }
      )
      .then(function () {
        M.Modal.getInstance(
          document.querySelector(".modal-add-manopera")
        ).close();
        router.push({
          query: M.toast({
            html: "Tip manopera adaugat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchManopera");
      });
  },
  updManopera({ dispatch }, updManopera) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/manopera/update",
        updManopera,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          query: M.toast({
            html: "Tip manopera actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchManopera");
      });
  },
  deleteManopera({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/manopera/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Tipul de manopera nu poate fi sters!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Tip manopera sters!",
              classes: "red",
            }),
          });
          dispatch("fetchManopera");
        }
        M.Modal.getInstance(
          document.querySelector(".modal-del-manopera")
        ).close();
      });
  },
  updPreturiVopsit({ dispatch }, updPreturiVopsit) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/preturi-vopsit/update",
        updPreturiVopsit,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          query: M.toast({
            html: "Pret actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchPreturiVopsit");
      });
  },
  addServ({ dispatch }, newServ) {
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/serv/add", newServ, {
        headers: headers,
      })
      .then(function () {
        M.Modal.getInstance(document.querySelector(".modal-add-serv")).close();
        router.push({
          query: M.toast({
            html: "Tip serviciu adaugat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchPreturiServ");
      });
  },
  updServ({ dispatch }, updServ) {
    Vue.http
      .put(process.env.VUE_APP_HUB_API_HOST + "api/serv/update", updServ, {
        headers: headers,
      })
      .then(function () {
        router.push({
          query: M.toast({
            html: "Tip serviciu actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchPreturiServ");
      });
  },
  deleteServ({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/serv/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Tipul serviciu nu poate fi sters!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Tip serviciu sters!",
              classes: "red",
            }),
          });
          dispatch("fetchPreturiServ");
        }
        M.Modal.getInstance(document.querySelector(".modal-del-serv")).close();
      });
  },
  //
  // Setari Garduri
  //
  fetchModeleGard({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/modelegard", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadModeleGard", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.error(error.body.message);
        commit("setLoading", false);
      });
  },
  fetchTipuriPanouGard({ commit }) {
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/tipuripanougard", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadTipuriPanouGard", response.body);
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  addModelGard({ dispatch }, newModel) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/modelegard/add",
        newModel,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({ query: M.toast({ html: "Model gard adaugat!" }) });
        dispatch("fetchModeleGard");
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  editModelGard({ dispatch }, updModel) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/modelegard/edit/" +
          updModel.id_model,
        updModel,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({ query: M.toast({ html: "Model gard actualizat!" }) });
        dispatch("fetchModeleGard");
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  deleteModelGard({ dispatch }, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST +
          "api/productie/modelegard/delete/" +
          id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          router.push({
            query: M.toast({
              html: "Model gard nu poate fi sters!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Model gard sters!",
            }),
          });
          dispatch("fetchModeleGard");
        }
        M.Modal.getInstance(document.querySelector(".modal-del-gard")).close();
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },

  //
  // Setari Contracte
  //
  fetchFirmeMontaj({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/firme_montaj", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadFirmeMontaj", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log("Error: " + error.status + " " + error.statusText);
        commit("setLoading", false);
      });
  },
  markFirmaActiva({ commit }, payload) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/updfirmamontajactiva",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        router.push({
          query: M.toast({ html: response.body, classes: "bg-green-500" }),
        });
        commit("setFirmaActiva", payload.id_firma_activa);
      })
      .catch(function (error) {
        console.error(error.body.message);
      });
  },
  //
  // Setari Oameni Productie
  //
  fetchOameniProductie({ commit }) {
    commit("setLoading", true);
    Vue.http
      .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/oameni", {
        headers: headers,
      })
      .then(function (response) {
        commit("loadOameniProductie", response.body);
        commit("setLoading", false);
      })
      .catch(function (error) {
        console.log(error);
        commit("setLoading", false);
      });
  },
  addOmProductie({ dispatch }, payload) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oameni/add",
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Persoana nu a fost adaugata!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Persoana adaugata!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchOameniProductie");
        }
      });
  },
  deleteOmProductie({ dispatch }, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/productie/oameni/delete/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Persoana nu a fost stearsa!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Persoana stersa!",
              classes: "bg-green-500",
            }),
          });
          dispatch("fetchOameniProductie");
        }
        M.Modal.getInstance(
          document.querySelector(".modal-del-persoana")
        ).close();
      });
  },
  //
  // Setari Consumabile
  //
  addConsumabil({ dispatch }, newObiect) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/consumabil/add",
        newObiect,
        {
          headers: headers,
        }
      )
      .then(function () {
        M.Modal.getInstance(
          document.querySelector(".modal-add-obiect")
        ).close();
        router.push({
          query: M.toast({
            html: "Produs adaugat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchConsumabile");
      });
  },
  updConsumabil({ dispatch }, updObiect) {
    Vue.http
      .put(
        process.env.VUE_APP_HUB_API_HOST + "api/consumabil/update",
        updObiect,
        {
          headers: headers,
        }
      )
      .then(function () {
        router.push({
          query: M.toast({
            html: "Produs actualizat!",
            classes: "bg-green-500",
          }),
        });
        dispatch("fetchConsumabile");
      });
  },
  deleteConsumabil({ dispatch }, id) {
    Vue.http
      .delete(
        process.env.VUE_APP_HUB_API_HOST + "api/consumabil/delete/" + id,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Produsul nu poate fi sters!",
              classes: "red",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Produs sters!",
              classes: "red",
            }),
          });
          dispatch("fetchConsumabile");
        }
        M.Modal.getInstance(
          document.querySelector(".modal-del-obiect")
        ).close();
      });
  },
  //
  // Setari Furnizori
  //
  //
  addFurnizor({ dispatch }, newMaterial) {
    Vue.http
      .post(
        process.env.VUE_APP_HUB_API_HOST + "api/furnizori/add",
        newMaterial,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        M.Modal.getInstance(
          document.querySelector(".modal-add-furnizor")
        ).close();
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Furnizor nu a fost adaugat!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Furnizor adaugat!",
            }),
          });
          dispatch("fetchFurnizori");
        }
      });
  },
  deleteFurnizor({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/furnizori/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          router.push({
            query: M.toast({
              html: "Furnizorul nu a fost sters!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Furnizor sters!",
            }),
          });
          dispatch("fetchFurnizori");
        }
        M.Modal.getInstance(
          document.querySelector(".modal-del-furnizor")
        ).close();
      });
  },
  // Setari Users
  //
  addUser({ dispatch }, newUser) {
    //console.log(newUser);
    Vue.http
      .post(process.env.VUE_APP_HUB_API_HOST + "api/user/add", newUser, {
        headers: headers,
      })
      .then(function () {
        M.Modal.getInstance(document.querySelector(".modal-add-user")).close();
        router.push({
          query: M.toast({
            html: "Utilizator adaugat!",
          }),
        });
        dispatch("fetchUsers");
      });
  },
  deleteUser({ dispatch }, id) {
    Vue.http
      .delete(process.env.VUE_APP_HUB_API_HOST + "api/user/delete/" + id, {
        headers: headers,
      })
      .then(function (response) {
        if (response.body.error) {
          console.error(response.body.error);
          alert("Utilizatorul are oferte sau comenzi!");
          router.push({
            query: M.toast({
              html: "Utilizatorul nu a fost sters!",
            }),
          });
        } else {
          router.push({
            query: M.toast({
              html: "Utilizatorul sters!",
            }),
          });
          dispatch("fetchUsers");
        }
        M.Modal.getInstance(document.querySelector(".modal-del-user")).close();
      });
  },
};
