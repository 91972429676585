<template>
	<div class="h-full bg-bg-grey">
		<div class="hero-image mx-auto mb-8">
			<div class="container">
				<div class="title">
					<h3 class="text-4xl font-medium uppercase">Home</h3>
					<!-- <p class="text-3xl">
						Salut <strong> {{ user.username.replace(/ .*/, '') }}</strong>
					</p> -->
				</div>
			</div>
			<img src="../assets/home.png" class="h-full mx-auto object-cover" />
		</div>
		<section v-if="user" class="container flex flex-col justify-start gap-10 mb-8">
			<v-card v-if="['admin', 'superuser'].includes(user.rol) || user.departament == 'vanzari'"
				class="rounded-lg w-full">
				<div class="w-full h-12 light-grey flex flex-row justify-start items-center px-8">
					<h2 class="text-white text-xl uppercase font-medium">Vanzari</h2>
				</div>
				<v-list class="grid grid-cols-6 gap-y-1 px-4">
					<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)" to="/cererisite">
						<v-icon color="primary" class="mr-2">mdi-message-text</v-icon>
						<v-list-item-title class="text-lg">Cereri site</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)" to="/oferte">
						<v-icon color="primary" class="mr-2">mdi-file-document-multiple</v-icon>
						<v-list-item-title class="text-lg">Ofertare produse</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)" to="/comenzi">
						<v-icon color="primary" class="mr-2">mdi-text-box-check</v-icon>
						<v-list-item-title class="text-lg">Comenzi produse</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)" to="/proforme">
						<v-icon color="primary" class="mr-2">mdi-invoice-text</v-icon>
						<v-list-item-title class="text-lg">Proforme</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin'].includes(user.rol)" to="/rapoarte">
						<v-icon color="primary" class="mr-2">mdi-chart-bar</v-icon>
						<v-list-item-title class="text-lg">Rapoarte</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-card>
			<v-card v-if="['admin', 'superuser'].includes(user.rol) || user.departament == 'productie'"
				class="rounded-l w-full">
				<div class="w-full h-12 light-grey flex flex-row justify-start items-center px-8">
					<h2 class="text-white text-xl uppercase font-medium">Productie</h2>
				</div>
				<v-list class="grid grid-cols-6 gap-y-1 px-4">
					<v-list-item v-if="['admin', 'superuser', 'user_productie'].includes(user.rol)"
						to="/oferte-productie">
						<v-icon color="primary" class="mr-2">mdi-file-document-multiple</v-icon>
						<v-list-item-title class="text-lg">Ofertare garduri</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser'].includes(user.rol)" to="/lucrari-productie">
						<v-icon color="primary" class="mr-2">mdi-robot-industrial</v-icon>
						<v-list-item-title class="text-lg">Lucrari productie</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser'].includes(user.rol)" to="/calendar-productie">
						<v-icon color="primary" class="mr-2">mdi-calendar-multiselect</v-icon>
						<v-list-item-title class="text-lg">Programare lucrari</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser', 'user_productie'].includes(user.rol)"
						to="/productie/proforme">
						<v-icon color="primary" class="mr-2">mdi-invoice-text</v-icon>
						<v-list-item-title class="text-lg">Proforme garduri</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser', 'user_productie'].includes(user.rol)"
						to="/productie/contracte">
						<v-icon color="primary" class="mr-2">mdi-file-sign</v-icon>
						<v-list-item-title class="text-lg">Contracte garduri</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser', 'partener'].includes(user.rol)"
						to="/productie/oferte-gard/constructii">
						<v-icon color="primary" class="mr-2">mdi-wall</v-icon>
						<v-list-item-title class="text-lg">Oferte zidarie gard</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-card>
		</section>
	</div>
</template>

<script>
export default {
	name: "Home",
	data() {
		return {
			msg: "Homepage",
		};
	},
	computed: {
		user() {
			return this.$store.getters.loggedUser;
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero-image {
	/* max-width: 1920px; */
	/* background-color: #f2f4f8; */
	@apply bg-main-grey relative h-96 md:h-80
}

.hero-image .title {
	/* color: #ffffff; */
	/* position: absolute; */
	/* top: 50%; */
	/* left: 50%; */
	/* transform: translate(-50%, -50%); */
	/* text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.21); */
	/* margin: 0; */
	@apply absolute top-1/3 text-white
}

/*
.top {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/ss3.jpg');
  background-repeat: no-repeat;
} */
</style>
