<template>
	<div class="container">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title-secondary">Adauga oferta productie</h1>
		</div>
		<v-form v-model="addOfferForm">
			<div class="grid grid-cols-2 gap-8">
				<v-card class="rounded-lg py-4 px-6">
					<h2 class="text-lg font-medium mb-4">Detalii oferta</h2>
					<div class="grid grid-cols-3 gap-4">
						<v-menu v-model="datepickerMenu" class="col-span-1" :close-on-content-click="false"
							transition="scale-transition" :nudge-right="40" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="computedDateFormatted" label="Data oferta"
									prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
									outlined></v-text-field>
							</template>
							<v-date-picker v-model="oferta.today" no-title
								@input="datepickerMenu = false"></v-date-picker>
						</v-menu>
						<v-select v-model="oferta.tip_lucrare" class="col-span-1" :items="typeItems" label="Tip lucrare"
							outlined></v-select>
						<v-select v-model="oferta.country" class="col-span-1" :items="countryItems" label="Tara"
							outlined></v-select>
						<v-text-field v-model="oferta.denumire" class="col-span-2" label="Denumire lucrare"
							outlined></v-text-field>
					</div>
				</v-card>

				<v-card class="rounded-lg py-4 px-6">
					<h2 class="text-lg font-medium mb-4">Detalii client</h2>
					<div>
						<v-autocomplete v-model="client" :loading="loading" :items="clienti" return-object flat
							label="Alege client" outlined></v-autocomplete>
					</div>
					<v-dialog v-model="addClientModal" max-width="600px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="teal" text class="uppercase" dark v-bind="attrs" v-on="on">
								Adauga client nou
							</v-btn>
						</template>
						<AddClient @clientName="client.text = $event" @closeModal="addClientModal = false" />
					</v-dialog>
				</v-card>
			</div>
		</v-form>

		<div class="flex flex-row justify-end gap-4 mt-4">
			<v-btn to="/oferte-productie" color="blue-grey" class="uppercase" dark x-large>Anuleaza</v-btn>
			<v-btn color="teal" dark class="uppercase" x-large @click="addOfertaProductie">Salveaza</v-btn>
		</div>


		<!-- <pre>{{ client }}</pre> -->
		<!-- <pre>{{ $data }}</pre> -->
	</div>
</template>

<script>
import moment from "moment";
import AddClient from "@/components/Clients/AddClient";

export default {
	name: "AddOfertaProductie",
	components: {
		AddClient,
	},
	data() {
		return {
			addOfferForm: false,
			addClientModal: false,
			loading: false,
			datepickerMenu: false,
			client: {
				value: "",
				text: "",
			},
			oferta: {
				today: moment().format("YYYY-MM-DD"),
				tip_lucrare: "gard",
				denumire: "gard din aluminiu vopsit electrostatic",
				moneda: "ron",
				country: "ro"
			},
			details: {
				t_executie: "60",
				garantie: "2 ani",
				c_plata: 50,
				detalii: "",
			},
		};
	},
	computed: {
		clienti() {
			return this.$store.getters.selectClienti;
		},
		returnClientId() {
			return this.$store.getters.returnClientId;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		termen() {
			let date = moment(this.oferta.today, "YYYY-MM-DD");
			let nr = this.oferta.t_plata;
			let data_plata = date.add(nr, "days").format("YYYY-MM-DD");
			return data_plata;
		},
		computedDateFormatted() {
			return this.formatDate(this.oferta.today)
		},
		typeItems() {
			const types = [
				{
					text: "Gard aluminiu",
					value: "gard"
				}
			]
			if (['admin', 'superuser'].includes(this.user.rol)) {
				const general = {
					text: "General",
					value: "general"
				}
				types.push(general)
			}
			return types
		},
		countryItems() {
			const countries = [
				{
					text: "Romania",
					value: "ro"
				}
			]
			if (['admin', 'superuser'].includes(this.user.rol) || this.user.email_user == 'alexandru.pietrariu@cuprolli.ro') {
				const austria = {
					text: "Austria",
					value: "at"
				}
				countries.push(austria)
			}
			return countries
		},
	},
	watch: {
		"oferta.tip_lucrare"(value) {
			if (value == "gard") {
				this.oferta.denumire = this.returnDefaultDenumire()
			} else {
				this.oferta.denumire = "";
			}
		},
		"oferta.country"() {
			if (this.oferta.tip_lucrare == "gard") {
				this.oferta.denumire = this.returnDefaultDenumire()
			} else {
				this.oferta.denumire = "";
			}
		},
		returnClientId: function (newVal) {
			this.client.value = newVal.toString();
		},
	},
	methods: {
		returnDefaultDenumire() {
			switch (this.oferta.country) {
				case 'ro':
					return "gard din aluminiu vopsit electrostatic"
				case 'at':
					return "Elektrostatisch Lackierter Aluminiumzaun"
				default:
					return
			}
		},
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		addOfertaProductie() {
			if (
				!this.client.value ||
				!this.oferta.today ||
				!this.oferta.moneda ||
				!this.oferta.tip_lucrare
			) {
				M.toast({
					html: "Completati toate campurile!",
				});
			} else {
				let newOfertaProductie = {
					client_id: this.client.value,
					data: this.oferta.today,
					tip_lucrare: this.oferta.tip_lucrare,
					country: this.oferta.country,
					denumire: this.oferta.denumire,
					moneda: this.oferta.moneda,
					color: this.oferta.tip_lucrare == "gard" ? "client nehotarat" : null,
					t_executie:
						this.oferta.tip_lucrare == "gard" ? this.details.t_executie : "",
					garantie:
						this.oferta.tip_lucrare == "gard" ? this.details.garantie : "",
					c_plata:
						this.oferta.tip_lucrare == "gard" ? this.details.c_plata : "",
					detalii:
						this.oferta.tip_lucrare == "gard" ? this.details.detalii : "",
					user_id: this.user.id_user,
				};

				// console.log(newOfertaProductie);
				this.$store.dispatch("addOfertaProductie", newOfertaProductie);
			}
		},
		fetchClienti() {
			this.$store.dispatch("fetchClienti");
		},
	},
	created: function () {
		this.fetchClienti();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
