<template>
	<div class="container pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Oferte configurator EU</h1>
		</div>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta oferta dupa client"></v-text-field>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<!-- <v-select ref="selectZone" v-model="selectedZone" class="w-60" :items="zones" item-text="denumire"
						item-value="value" :clearable="['admin', 'superuser'].includes(user.rol)"
						@click:clear="$refs.selectZone.blur()" label="Toate zonele" solo hide-details>
						<template v-slot:item="{ item }">
							<span>
								{{ item.denumire }}
							</span>
							<span class="w-5 h-5 rounded-full ml-2" :class="'bg-' + item.color"></span>
						</template>
<template v-slot:selection="{ item }">
							<span>
								{{ item.denumire }}
							</span>
							<span class="w-5 h-5 rounded-full ml-2" :class="'bg-' + item.color"></span>
						</template>
</v-select> -->
				</div>
			</div>
		</v-toolbar>

		<!-- <v-data-table :headers="headers" :items="filterBy(oferte_productie, filterInput)" item-key="id_cerere"
			:loading="loadingOferteConfigurator" show-expand single-expand :expanded.sync="expanded"
			:items-per-page="-1" hide-default-footer>

		</v-data-table> -->

		<div v-if="oferte_productie.length > 0" class="card-panel">
			<table class="highlight responsive-table">
				<thead>
					<tr>
						<th>Nr.</th>
						<th>Data oferta</th>
						<th class="w-60">Client</th>
						<th class="w-32">Tara / zona</th>
						<th class="text-center">Contact</th>
						<th>
							Preturi / m² <br />
							<span style="font-weight: 400">panouri / poarta mica / poarta auto</span>
						</th>
						<th>
							Suprafata <br />
							totala
						</th>
						<th>Pret total cu TVA</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="oferta in filterBy(oferte_productie, filterInput)" :key="oferta.id_cerere">
						<td :class="{ 'bold italic': oferta.gard_obj.model == 'vertical' }">
							{{ oferta.id_cerere }}
						</td>
						<td>{{ oferta.data | formatDate }}</td>
						<td>
							{{ fullName(oferta.client_obj.surname, oferta.client_obj.name) }}
							&nbsp; {{ returnTimeframeNumber(oferta.client_obj.timeframe) }}
							<span class="dot" :class="returnTimeframeColor(oferta.client_obj.timeframe)"></span>
						</td>
						<td>{{ oferta.country.toUpperCase() }} / {{ oferta.client_obj.judet.text }}</td>
						<td class="text-center">
							<i v-if="oferta.client_obj.email" style="cursor: pointer" v-tooltip="{
								content: oferta.client_obj.email + '<br>' + oferta.client_obj.phone, trigger: 'click',
								placement: 'right',
								classes: ['grey', 'lighten-3', 'pa-10'],
								targetClasses: ['material-icons'],
								offset: 10,
								delay: {
									show: 500,
									hide: 300,
								},
							}">local_phone</i>
						</td>
						<td class="w-60">
							{{ panouriPrice(oferta) }} eur /
							{{ poarta_pietonalaPrice(oferta) }} eur /
							{{ poarta_autoPriceStr(oferta) }} eur
						</td>
						<td class="w-16">
							{{
								suprafataTotala(
									oferta.gard_obj.suprafataPanouri,
									oferta.gard_obj.suprafataPP,
									oferta.gard_obj.suprafataPA
								)
							}}
							m²
						</td>
						<td>
							{{ oferta.gard_obj.pretTotal }} eur
						</td>
						<td>
							<div>
								<a :href="`https://hub.cuprolli.ro/docs/configurator/eu/zaun_angebot_${oferta.id_cerere}.pdf`"
									target="_blank" :download="`oferta_gard_eu_${oferta.id_cerere}`">
									<i v-tooltip="{
										content: 'Descarca oferta',
										trigger: 'hover',
										placement: 'top',
										classes: ['teal', 'lighten-3', 'pa-10'],
										targetClasses: [
											'material-icons',
											'btn-icon',
											'teal-text',
											'align-middle',
											'ml-10',
										],
										offset: 10,
										delay: {
											show: 500,
											hide: 300,
										},
									}">cloud_download</i>
								</a>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- <pre>
			{{ oferte_productie }}
		</pre> -->
	</div>
</template>

<script>

export default {
	name: "OferteProductieConfiguratorEU",
	components: {
	},
	data() {
		return {
			filterInput: "",
			expanded: [],
			headers: [
				{
					text: 'Nr.',
					align: 'start',
					sortable: false,
					value: 'id_cerere'
				},
				{
					text: 'Data',
					align: 'start',
					sortable: false,
					value: 'data',
				},
				{
					text: 'Client',
					align: 'start',
					sortable: false,
					value: 'nume',
				},
				{
					text: '',
					align: 'start',
					sortable: false,
					value: 'bricks',
				},
				{
					text: 'Sunat?',
					align: 'center',
					sortable: false,
					value: 'sunat',
					width: '5%'
				},
				{
					text: 'Interes',
					align: 'start',
					sortable: false,
					value: 'interes',
					width: '15%'
				},
				{
					text: 'Trimis oferta?',
					align: 'start',
					sortable: false,
					value: 'trimisa',
				},
				{
					text: 'Judet',
					align: 'start',
					sortable: false,
					value: 'judet',
				},
				{
					text: 'Suprafata totala',
					align: 'start',
					sortable: false,
					value: 'suprafata',
				},
				{
					text: 'Pret total cu TVA',
					align: 'start',
					sortable: false,
					value: 'total',
					width: '10%'
				},
				{
					value: 'data-table-expand',
					width: '5%'
				},
				{
					value: 'actions',
					sortable: false,
					width: '5%'
				}
			],
			prices: {
				p_rama: 1.27, // %
				pp: 237, // eur
				pa: 126, // eur
				pap: 254, // eur
				shippingKM: 0.6,
			},
		};
	},
	computed: {
		oferte_productie() {
			return this.$store.getters.oferte_productie_configurator_eu;
		},
		loading() {
			return this.$store.getters.loading;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
	},
	methods: {
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);

			return list.filter((oferta) => searchRegex.test(this.fullName(oferta.client_obj.surname, oferta.client_obj.name)))
		},
		fullName(firstname, lastname) {
			return `${firstname} ${lastname}`
		},
		returnTimeframeNumber(str) {
			if (str) {
				if (str == "so bald wie möglich") {
					return 1;
				} else if (str == "bald") {
					return 2;
				} else if (str == "innerhalb eines halben Jahres") {
					return 3;
				} else if (str == "Ich schaue es mir gerade erst an") {
					return 4;
				}
			}
		},
		returnTimeframeColor(str) {
			if (str) {
				if (str == "so bald wie möglich" || str == "bald") {
					return "green";
				} else {
					return "grey";
				}
			}
		},
		panouriPrice(oferta) {
			let price = oferta.client_obj.judet.price;
			if (oferta.gard_obj.configuration.panouri.panelFrame) {
				price = (price * this.prices.p_rama).toFixed(2);
			}
			if (oferta.gard_obj.model == "vertical") {
				price += 15;
			}
			return Number(price);
		},
		poarta_pietonalaPrice(oferta) {
			let price = oferta.client_obj.judet.price + this.prices.pp;
			if (oferta.gard_obj.model == "vertical") {
				price += 15;
			}
			return price;
		},
		poarta_autoPriceStr(oferta) {
			let pricePA = oferta.client_obj.judet.price + this.prices.pa;
			let pricePAP = oferta.client_obj.judet.price + this.prices.pap;

			if (oferta.gard_obj.model == "vertical") {
				pricePA += 15;
				pricePAP += 15;
			}
			let str = "";

			if (oferta.gard_obj.configuration.poarta_a.items) {
				if (this.poarta_aap_buc(oferta.gard_obj.configuration) > 0) {
					if (
						this.poarta_ab_buc(oferta.gard_obj.configuration) > 0 ||
						this.poarta_ac_buc(oferta.gard_obj.configuration) > 0
					) {
						str = `${pricePA} & ${pricePAP}`;
					} else {
						str = `${pricePAP}`;
					}
				} else {
					str = `${pricePA}`;
				}
			} else {
				str = `${pricePA}`;
			}
			return str;
		},
		poarta_ab_buc(configuration) {
			let count = 0;
			configuration.poarta_a.items.forEach((poarta) => {
				if (poarta.tip == "batanta") {
					count++;
				}
			});
			return count;
		},
		poarta_ac_buc(configuration) {
			let count = 0;
			configuration.poarta_a.items.forEach((poarta) => {
				if (poarta.tip == "culisanta") {
					count++;
				}
			});
			return count;
		},
		poarta_aap_buc(configuration) {
			let count = 0;
			configuration.poarta_a.items.forEach((poarta) => {
				if (poarta.tip == "autoportanta") {
					count++;
				}
			});
			return count;
		},
		suprafataTotala(a, b, c) {
			let s = Number(a) + Number(b) + Number(c);
			return s.toFixed(2);
		},
	},
	created: function () {
		this.$store.dispatch("fetchOferteProductieConfiguratorEU");
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.dot {
	border-radius: 100px;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin: 0 5px 0 15px;
}

span.square {
	width: 12px;
	height: 12px;
	display: inline-block;
	margin: 0 5px 0 10px;
}
</style>
