export default {
  setLoading(state, item) {
    state.loading = item;
  },
  setLoadingCompanyDetails(state, item) {
    state.loadingCompanyDetails = item;
  },
  setLoadingOferte(state, item) {
    state.loadingOferte = item;
  },
  setLoadingOferta(state, item) {
    state.loadingOferta = item;
  },
  setLoadingClienti(state, item) {
    state.loadingClienti = item;
  },
  setLoadingClient(state, item) {
    state.loadingClient = item;
  },
  setLoadingCereri(state, item) {
    state.loadingCereri = item;
  },
  setLoadingCerere(state, item) {
    state.loadingCerere = item;
  },
  setLoadingAliaje(state, item) {
    state.loadingAliaje = item;
  },
  setLoadingReports(state, item) {
    state.loadingReports = item;
  },
  setLoadingOferteGarduri(state, item) {
    state.loadingOferteGarduri = item;
  },
  setLoadingOfertaGarduri(state, item) {
    state.loadingOfertaGarduri = item;
  },
  setLoadingOferteConfigurator(state, item) {
    state.loadingOferteConfigurator = item;
  },
  setLoadingListaReveniriConfigurator(state, item) {
    state.loadingListaReveniriConfigurator = item;
  },
  setLoadingOferteConfiguratorEU(state, item) {
    state.loadingOferteConfiguratorEU = item;
  },
  setLoadingProforme(state, item) {
    state.loadingProforme = item;
  },
  setLoadingProformeProductie(state, item) {
    state.loadingProformeProductie = item;
  },
  setLoadingContracte(state, item) {
    state.loadingContracte = item;
  },
  loadWPCereri(state, items) {
    state.wpCereri = items;
  },
  loadMoreWPCereri(state, items) {
    items.forEach((item) => {
      state.wpCereri.push(item);
    });
  },
  loadWPDetails(state, payload) {
    state.wpDetails = payload;
  },
  loadWPCerere(state, item) {
    state.wpCerere = item;
  },
  loadWPOrderReports(state, items) {
    state.wpOrderReports = items;
  },
  loadTempCerere(state, payload) {
    state.objCerere = payload;
  },
  loadOferte(state, items) {
    // items.forEach(item => state.oferte.push(item))
    state.oferte = items;
  },
  setOffersSearch(state, isSearch) {
    state.isOffersSearchList = isSearch;
  },
  addNewOferta(state, newOferta) {
    state.oferte.unshift(newOferta);
  },
  loadOferta(state, item) {
    if (item.produse_obj) {
      item.produse_obj = JSON.parse(item.produse_obj);
    }
    if (item.custom_obj) {
      item.custom_obj = JSON.parse(item.custom_obj);
    }
    if (item.debitare_obj) {
      item.debitare_obj = JSON.parse(item.debitare_obj);
    }
    if (item.transport_obj) {
      item.transport_obj = JSON.parse(item.transport_obj);
    }
    if (item.cc_obj) {
      item.cc_obj = JSON.parse(item.cc_obj);
    } else {
      item.cc_obj = [];
    }
    state.oferta = item;
  },
  deleteOferta(state, id) {
    const idx = state.oferte.findIndex((oferta) => {
      return oferta.id === id;
    });

    state.oferte.splice(idx, 1);
  },
  updOfertaStatus(state, payload) {
    const idx = state.oferte.findIndex((oferta) => {
      return oferta.id === payload.id;
    });
    state.oferte[idx].status_oferta = payload.status_oferta;
  },
  loadCCMail(state, mail) {
    state.oferta.cc_obj.push(mail);
  },
  loadContracteProductie(state, items) {
    items.forEach(function (item) {
      if (item.detalii) {
        item.detalii = JSON.parse(item.detalii);
      }
      if (item.gard_obj) {
        item.gard_obj = JSON.parse(item.gard_obj);
      }
      if (item.alte_obj) {
        item.alte_obj = JSON.parse(item.alte_obj);
      }
      if (item.carport_info) {
        item.carport_info = JSON.parse(item.carport_info);
      }
    });
    state.contracteProductie = items;
  },
  loadContractProductie(state, item) {
    if (item.detalii) {
      item.detalii = JSON.parse(item.detalii);
    }
    if (item.gard_obj) {
      item.gard_obj = JSON.parse(item.gard_obj);
    }
    if (item.alte_obj) {
      item.alte_obj = JSON.parse(item.alte_obj);
    }
    if (item.text_content) {
      item.text_content = JSON.parse(item.text_content);
    }
    if (item.aditional_content) {
      item.aditional_content = JSON.parse(item.aditional_content);
    }
    state.contractProductie = item;
  },
  setActAditionalValues(state, id) {
    state.contractProductie.id_aditional = id;
  },
  loadActAditionalDetails(state, payload) {
    state.aditionalExtra = payload;
    if (payload.gard_obj) {
      state.aditionalExtra.gard_obj = JSON.parse(payload.gard_obj);
    }
    if (payload.alte_obj) {
      state.aditionalExtra.alte_obj = JSON.parse(payload.alte_obj);
    }
  },
  updateContractWCarportInfo(state, payload) {
    const contract = state.contracteProductie.find(
      (contract) => contract.id_contract == payload.contract_id
    );
    contract.carport_info = payload.carport_info;
  },
  updContractStatusReview(state, payload) {
    const idx = state.contracteProductie.findIndex((contract) => {
      return contract.id_contract === payload.id;
    });
    state.contracteProductie[idx].status_review = payload.status_review;
    state.contracteProductie[idx].status_review_obs = payload.status_review_obs;
  },
  loadFirmeMontaj(state, items) {
    state.firme_montaj = items;
  },
  setFirmaActiva(state, id) {
    state.firme_montaj.forEach((firma) => {
      firma.id_firma_activa = null;
      if (firma.id_firma == id) {
        firma.id_firma_activa = id;
      }
    });
  },
  loadProforme(state, items) {
    items.forEach(function (item) {
      item.produse_obj = JSON.parse(item.produse_obj);
      item.custom_obj = JSON.parse(item.custom_obj);
      if (item.debitare_obj) {
        item.debitare_obj = JSON.parse(item.debitare_obj);
      }
      if (item.transport_obj) {
        item.transport_obj = JSON.parse(item.transport_obj);
      }
    });
    state.proforme = items;
  },
  loadProformeProductie(state, items) {
    items.forEach(function (item) {
      if (item.produse_obj) {
        item.produse_obj = JSON.parse(item.produse_obj);
      }
      if (item.manopera_obj) {
        item.manopera_obj = JSON.parse(item.manopera_obj);
      }
      if (item.vopsit_obj) {
        item.vopsit_obj = JSON.parse(item.vopsit_obj);
      }
      if (item.serv_obj) {
        item.serv_obj = JSON.parse(item.serv_obj);
      }
      if (item.consumabile_obj) {
        item.consumabile_obj = JSON.parse(item.consumabile_obj);
      }
      if (item.alte_obj) {
        item.alte_obj = JSON.parse(item.alte_obj);
      }
      if (item.gard_obj) {
        item.gard_obj = JSON.parse(item.gard_obj);
      }
    });
    state.proformeProductie = items;
  },
  loadProforma(state, item) {
    state.proforma = item;
    if (item.produse_obj) {
      state.proforma.produse_obj = JSON.parse(item.produse_obj);
    }
    if (item.custom_obj) {
      state.proforma.custom_obj = JSON.parse(item.custom_obj);
    }
    if (item.debitare_obj) {
      state.proforma.debitare_obj = JSON.parse(item.debitare_obj);
    }
    if (item.transport_obj) {
      state.proforma.transport_obj = JSON.parse(item.transport_obj);
    }
  },
  setProformaGeneralStatus(state, payload) {
    let idx = state.proforme.findIndex(
      (proforma) => proforma.id_proforma === payload.id
    );
    const status = payload.status == "nedefinit" ? null : payload.status;
    state.proforme[idx].general_status = status;
  },
  setProformaPaidStatus(state, payload) {
    let idx = state.proforme.findIndex(
      (proforma) => proforma.id_proforma === payload.id
    );
    state.proforme[idx].paid_status = payload.paid_status;
  },
  setProformaProductiePaidStatus(state, payload) {
    let idx = state.proformeProductie.findIndex(
      (proforma) => proforma.id_proforma === payload.id
    );
    state.proformeProductie[idx].paid_status = payload.paid_status;
  },
  loadProformaProductie(state, item) {
    state.proformaProductie = item;
    if (item.produse_obj) {
      state.proformaProductie.produse_obj = JSON.parse(item.produse_obj);
    }
    if (item.manopera_obj) {
      state.proformaProductie.manopera_obj = JSON.parse(item.manopera_obj);
    }
    if (item.vopsit_obj) {
      state.proformaProductie.vopsit_obj = JSON.parse(item.vopsit_obj);
    }
    if (item.serv_obj) {
      state.proformaProductie.serv_obj = JSON.parse(item.serv_obj);
    }
    if (item.consumabile_obj) {
      state.proformaProductie.consumabile_obj = JSON.parse(
        item.consumabile_obj
      );
    }
    if (item.alte_obj) {
      state.proformaProductie.alte_obj = JSON.parse(item.alte_obj);
    }
    if (item.gard_obj) {
      state.proformaProductie.gard_obj = JSON.parse(item.gard_obj);
    }
  },
  loadComenziActive(state, items) {
    items.forEach(function (item) {
      item.produse_obj = JSON.parse(item.produse_obj);
      item.custom_obj = JSON.parse(item.custom_obj);
      if (item.debitare_obj) {
        item.debitare_obj = JSON.parse(item.debitare_obj);
      }
      if (item.transport_obj) {
        item.transport_obj = JSON.parse(item.transport_obj);
      }
    });
    state.comenzi_active = items;
  },
  loadComenziFinalizate(state, items) {
    state.comenzi_finalizate = items;
  },
  loadAllUserComenzi(state, items) {
    state.comenzi_by_user = items;
  },
  loadAllUserCurrentComenzi(state, items) {
    state.current_comenzi_by_user = items;
  },
  loadAllBonusComenzi(state, items) {
    state.comenzi_bonus = items;
  },
  loadComanda(state, items) {
    state.comanda = items;
    if (items.produse_obj) {
      state.comanda.produse_obj = JSON.parse(items.produse_obj);
    }
    if (items.custom_obj) {
      state.comanda.custom_obj = JSON.parse(items.custom_obj);
    }
    if (items.debitare_obj) {
      state.comanda.debitare_obj = JSON.parse(items.debitare_obj);
    }
    if (items.transport_obj) {
      state.comanda.transport_obj = JSON.parse(items.transport_obj);
    }
  },
  changeOrderedStatus(state, payload) {
    let idx = state.comenzi_active.findIndex(
      (obj) => obj.id_comanda === payload.id
    );
    state.comenzi_active[idx].marfa_comandata = payload.marfa_comandata;
  },
  updateProblemaStatus(state, payload) {
    let idx = state.comenzi_active.findIndex(
      (obj) => obj.id_comanda === payload.id
    );
    state.comenzi_active[idx].problema = payload.object.status;
    state.comenzi_active[idx].problema_text = payload.object.problema_text;
  },
  changeStareComanda(state, payload) {
    let idx = state.comenzi_active.findIndex(
      (obj) => obj.id_comanda === payload.id
    );
    state.comenzi_active[idx].stare = payload.stare;
  },
  loadContract(state, item) {
    state.contract.docURL = item;
  },
  loadContractMeta(state, obj) {
    state.contract.id_doc = obj.id;
    state.contract.name = obj.filename;
    state.contract.type = obj.doctype;
  },
  loadFactura(state, item) {
    state.factura.docURL = item;
  },
  loadFacturaMeta(state, obj) {
    state.factura.id_doc = obj.id;
    state.factura.name = obj.filename;
    state.factura.type = obj.doctype;
  },
  loadAwb(state, item) {
    state.awb.docURL = item;
  },
  loadAwbMeta(state, obj) {
    state.awb.id_doc = obj.id;
    state.awb.name = obj.filename;
    state.awb.type = obj.doctype;
  },
  loadCurrency(state, items) {
    state.curs = items;
  },
  loadBonus(state, items) {
    state.bonus = items;
  },
  loadClientiCount(state, item) {
    state.clientiCount = Number(item);
  },
  loadClienti(state, items) {
    state.clienti = items;
  },
  loadClientiData(state, items) {
    state.clientiData = items;
  },
  loadClient(state, item) {
    state.client = item;
    if (item.cc_obj) {
      state.client.cc_obj = JSON.parse(item.cc_obj);
    } else {
      state.client.cc_obj = [];
    }
  },
  addNewClient(state, newClient) {
    state.clientiData.unshift(newClient);
  },
  loadReturnClientId(state, id) {
    state.returnClientId = id;
  },
  deleteClient(state, id) {
    const delIdx = state.clientiData.findIndex(
      (client) => client.id_client == id
    );
    state.clientiData.splice(delIdx, 1);
  },
  loadUser(state, items) {
    state.user = items;
  },
  userLoaded(state, payload) {
    state.userLoaded = payload;
  },
  loadUsers(state, items) {
    state.users = items;
  },
  loadProfile(state, items) {
    state.profile = [];
    // let profile = []
    items.forEach(function (item) {
      item.profil_cote = JSON.parse(item.profil_cote);
      // let profil = {
      //   profil_id: item.profil_id,
      //   profil_tip: item.profil_tip,
      //   profil_cote: JSON.parse(item.profil_cote),
      //   profil_greutate_ml: item.profil_greutate_ml,
      //   profil_grad_dif: item.profil_grad_dif,
      //   profil_buc1t: item.profil_buc1t
      // }
      // state.profile.push(profil)
    });
    state.profile = items;
  },
  loadProfil(state, item) {
    item.profil_cote = JSON.parse(item.profil_cote);
    state.profil = item;
  },
  loadEchipeDeb(state, items) {
    state.echipeDeb = [];

    items.forEach(function (item) {
      let echipa = {
        echipa_id: item.echipa_id,
        echipa_nume: JSON.parse(item.echipa_nume),
        echipa_utilaj: item.echipa_utilaj,
      };
      state.echipeDeb.push(echipa);
    });
  },
  loadStocProfile(state, items) {
    // // load same items as separate lines
    // items.forEach(function(item){
    //   item.comenzi_obj = JSON.parse(item.comenzi_obj)
    //
    //   state.stocProfile.push(item)
    // })

    //load same items cumulated
    state.stocProfile = [];
    let list = [];
    items.forEach(function (item) {
      if (item.comenzi_obj) {
        item.comenzi_obj = JSON.parse(item.comenzi_obj);
      }
      let idx = list.findIndex(
        (obj) =>
          obj.profil_tip === item.profil_tip &&
          obj.cota_profil === item.cota_profil
      );
      if (idx >= 0) {
        list[idx].cantitate =
          Number(list[idx].cantitate) + Number(item.cantitate);
        list[idx].stoc_id = item.stoc_id;
        list[idx].avizat = item.avizat;
      } else {
        list.push(item);
      }
    });
    state.stocProfile = list;
  },
  loadComenziDeb(state, items) {
    state.comenziDeb = [];
    items.forEach(function (item) {
      item.comanda_obj = JSON.parse(item.comanda_obj);
      // item.avizata = Number(item.avizata)

      state.comenziDeb.push(item);
    });
  },
  loadComenziDebFinalizate(state, items) {
    state.comenziDebFinalizate = [];
    items.forEach(function (item) {
      item.comanda_obj = JSON.parse(item.comanda_obj);

      state.comenziDebFinalizate.push(item);
    });
  },
  loadDebitari(state, items) {
    state.debitari = [];
    items.forEach(function (item) {
      item.comanda_obj = JSON.parse(item.comanda_obj);
      state.debitari.push(item);
    });
  },
  loadListaDebitari(state, items) {
    state.listaDebitari = [];
    items.forEach(function (item) {
      item.comanda_obj = JSON.parse(item.comanda_obj);
      state.listaDebitari.push(item);
    });
  },
  loadDebitare(state, item) {
    item.comanda_obj = JSON.parse(item.comanda_obj);
    state.debitare = item;
  },
  deleteDebitare(state, id) {
    let idx = state.debitari.findIndex((obj) => obj.id_debitare == id);
    state.debitari.splice(idx, 1);
  },
  loadStocScrap(state, items) {
    state.pachete = items.find((obj) => {
      return obj.denumire == "pachete";
    });
    state.saci = items.find((obj) => {
      return obj.denumire == "saci";
    });
    state.span = items.find((obj) => {
      return obj.denumire == "span";
    });
  },
  updatePachete(state, buc) {
    state.pachete.buc = buc;
  },
  updateSaci(state, buc) {
    state.saci.buc = buc;
  },
  updateSpan(state, buc) {
    state.span.buc = buc;
  },
  loadMateriale(state, items) {
    state.materiale = items;
  },
  loadListaProduse(state, items) {
    state.listaProduse = items;
  },
  loadAliaje(state, items) {
    state.aliaje = items;
  },
  loadConsumabile(state, items) {
    state.consumabile = items;
  },
  loadManopera(state, items) {
    state.manopera = items;
  },
  loadPreturiVanzari(state, items) {
    items.forEach(function (item) {
      item.adaos_aliaje = JSON.parse(item.adaos_aliaje);
    });
    state.preturiVanzari = items;
  },
  loadFormulaVanzari(state, item) {
    state.formulaVanzari = item;
  },
  loadLME(state, payload) {
    state.LME = payload;
  },
  loadLevelsVanzari(state, payload) {
    state.levelsVanzari = payload;
  },
  loadFixedPricesMateriePrima(state, payload) {
    state.fixedPricesMateriePrima = payload;
  },
  loadPreturiDebitare(state, items) {
    state.preturiDebitare = items;
  },
  loadPreturiTransport(state, items) {
    state.preturiTransport = items;
  },
  loadPreturiVopsit(state, items) {
    state.preturiVopsit = items;
  },
  loadPreturiServ(state, items) {
    state.preturiServ = items;
  },
  loadFurnizori(state, items) {
    state.furnizori = items;
  },
  loadModeleGard(state, items) {
    state.modele_gard = items;
  },
  setFenceOffersSearch(state, isSearch) {
    state.isFenceOffersSearchList = isSearch;
  },
  setProformeSearch(state, isSearch) {
    state.isProformeSearchList = isSearch;
  },
  setProformeProductieSearch(state, isSearch) {
    state.isProformeProductieSearchList = isSearch;
  },
  setContractsSearch(state, isSearch) {
    state.isContractsSearchList = isSearch;
  },
  loadOferteProductieGarduri(state, items) {
    state.oferte_productie_garduri = items;
  },
  loadOferteProductieConfiguratorGard(state, items) {
    if (items.length > 0) {
      items.forEach(function (item) {
        item.client_obj = JSON.parse(item.client_obj);
        item.gard_obj = JSON.parse(item.gard_obj);
      });
    }
    state.oferte_productie_configurator_gard = items;
  },
  loadListaReveniriConfiguratorGardCounts(state, items) {
    state.lista_reveniri_configurator_gard_counts = items;
  },
  loadListaReveniriConfiguratorGard(state, items) {
    if (items.length > 0) {
      items.forEach(function (item) {
        item.client_obj = JSON.parse(item.client_obj);
      });
    }
    state.lista_reveniri_configurator_gard = items;
  },
  updateReturnDateOfertaProductieConfiguratorGard(state, payload) {
    let idx = state.lista_reveniri_configurator_gard.findIndex(
      (obj) => obj.id == payload.id
    );
    state.lista_reveniri_configurator_gard[idx].return_date =
      payload.return_date;
  },
  updateReturnStatusOfertaProductieConfiguratorGard(state, payload) {
    let idx = state.lista_reveniri_configurator_gard.findIndex(
      (obj) => obj.id == payload.id
    );
    // if (payload.completed == '1') {
    //   state.lista_reveniri_configurator_gard.splice(idx,1)
    // } else {
    //   state.lista_reveniri_configurator_gard[idx].completed = payload.completed
    // }
    state.lista_reveniri_configurator_gard[idx].completed = payload.completed;
    state.lista_reveniri_configurator_gard[idx].obs = payload.obs;
  },
  deleteReturnItemProductieConfiguratorGard(state, id) {
    let idx = state.lista_reveniri_configurator_gard.findIndex(
      (obj) => obj.id == id
    );
    state.lista_reveniri_configurator_gard.splice(idx, 1);
  },
  loadNextOferteProductieConfiguratorGard(state, items) {
    items.forEach(function (item) {
      item.client_obj = JSON.parse(item.client_obj);
      item.gard_obj = JSON.parse(item.gard_obj);
      state.oferte_productie_configurator_gard.push(item);
    });
    // state.oferte_productie_configurator_gard.concat(items)
  },
  loadOferteProductieConfiguratorEU(state, items) {
    if (items.length > 0) {
      items.forEach(function (item) {
        item.client_obj = JSON.parse(item.client_obj);
        item.gard_obj = JSON.parse(item.gard_obj);
      });
    }
    state.oferte_productie_configurator_eu = items;
  },
  updateInteresStatusOfertaProductieConfiguratorGard(state, payload) {
    let idx = state.oferte_productie_configurator_gard.findIndex(
      (obj) => obj.id_cerere == payload.id
    );
    state.oferte_productie_configurator_gard[idx].interes = payload.interes;
    state.oferte_productie_configurator_gard[idx].interes_details =
      payload.details;
  },
  markCalledOfertaProductieConfiguratorGard(state, payload) {
    let idx = state.oferte_productie_configurator_gard.findIndex(
      (obj) => obj.id_cerere == payload.id
    );
    state.oferte_productie_configurator_gard[idx].called = payload.called;
    state.oferte_productie_configurator_gard[idx].called_user = payload.user;
    state.oferte_productie_configurator_gard[idx].called_date =
      payload.called == 0 ? null : new Date().toISOString().substring(0, 10);
  },
  markSentOfertaOfertaProductieConfiguratorGard(state, payload) {
    let idx = state.oferte_productie_configurator_gard.findIndex(
      (obj) => obj.id_cerere == payload.id
    );
    state.oferte_productie_configurator_gard[idx].is_sent_oferta =
      payload.is_sent_oferta;
  },
  loadCereriConstructieConfiguratorGard(state, items) {
    if (items.length > 0) {
      items.forEach(function (item) {
        item.client_obj = JSON.parse(item.client_obj);
        item.bricks_obj = JSON.parse(item.bricks_obj);
      });
    }
    state.oferte_constructii_configurator_gard = items;
  },
  markCalledOfertaProductieBoltariConfiguratorGard(state, payload) {
    let idx = state.oferte_constructii_configurator_gard.findIndex(
      (obj) => obj.id_bricks == payload.id
    );
    state.oferte_constructii_configurator_gard[idx].called = payload.called;
  },
  updateInteresStatusCerereBricksConfiguratorGard(state, payload) {
    let idx = state.oferte_constructii_configurator_gard.findIndex(
      (obj) => obj.id_bricks == payload.id
    );
    state.oferte_constructii_configurator_gard[idx].interes = payload.interes;
    state.oferte_constructii_configurator_gard[idx].interes_details =
      payload.details;
  },
  loadOferteProductieGeneral(state, items) {
    state.oferte_productie_general = items;
  },
  loadAllUserLucrari(state, items) {
    state.oferte_productie_by_user = items;
  },
  loadAllBonusLucrari(state, items) {
    state.oferte_productie_bonus = items;
  },
  loadCurrentOfertaProductie(state, item) {
    if (item.produse_obj) {
      item.produse_obj = JSON.parse(item.produse_obj);
    }
    if (item.manopera_obj) {
      item.manopera_obj = JSON.parse(item.manopera_obj);
    }
    if (item.vopsit_obj) {
      item.vopsit_obj = JSON.parse(item.vopsit_obj);
    }
    if (item.serv_obj) {
      item.serv_obj = JSON.parse(item.serv_obj);
    }
    if (item.consumabile_obj) {
      item.consumabile_obj = JSON.parse(item.consumabile_obj);
    }
    if (item.alte_obj) {
      item.alte_obj = JSON.parse(item.alte_obj);
    }
    if (item.gard_obj) {
      item.gard_obj = JSON.parse(item.gard_obj);
    }
    state.currentOfertaProductie = item;
  },
  changeOfertaProductieStatus(state, payload) {
    state.currentOfertaProductie.status_lucrare = payload.status;
  },
  updateCurrentOfertaProductieExecutie(state, payload) {
    state.currentOfertaProductie.t_executie = payload.t_executie;
    state.currentOfertaProductie.garantie = payload.garantie;
  },
  updateCurrentOfertaProductieStatusMontaj(state, payload) {
    state.currentOfertaProductie.montaj_inclus = payload.montaj_inclus;
  },
  updateCurrentOfertaProductieDetaliiPlata(state, payload) {
    state.currentOfertaProductie.c_plata = payload.c_plata;
    state.currentOfertaProductie.c_plata2 = payload.c_plata2;
  },
  updateCurrentOfertaProductieDetalii(state, detalii) {
    state.currentOfertaProductie.detalii = detalii;
  },
  loadLucrari(state, items) {
    state.lucrari = items;
  },
  loadCurrentLucrare(state, items) {
    state.currentLucrare = items;
    if (items.echipa) {
      state.currentLucrare.echipa = JSON.parse(items.echipa);
    }
    if (items.gard_obj) {
      state.currentLucrare.gard_obj = JSON.parse(items.gard_obj);
    }
  },
  updateLucrareEchipa(state, payload) {
    state.currentLucrare.echipa = payload;
  },
  updateExTimeLucrare(state, payload) {
    state.currentLucrare.t_executie = payload.t_executie;
  },
  loadOameniProductie(state, items) {
    state.oameni_productie = items;
  },
  loadCalendarLucrari(state, items) {
    // state.calendarEvents = items
    state.calendarEvents = [];
    items.forEach(function (item) {
      if (item.echipa != null) {
        item.echipa = JSON.parse(item.echipa);
      }
      state.calendarEvents.push(item);
    });
  },
  addCalendarLucrare(state, item) {
    state.calendarEvents.push(item);
  },
  addLucrareDates(state, payload) {
    state.currentLucrare.start = payload.start;
    state.currentLucrare.end = payload.end;
  },
  deleteCalendarLucrare(state, id) {
    let idx = state.calendarEvents.findIndex((obj) => obj.id_event == id);
    state.calendarEvents.splice(idx, 1);
  },
  loadAvize(state, items) {
    state.avize = [];
    items.forEach(function (item) {
      if (item.obj_mat) {
        item.obj_mat = JSON.parse(item.obj_mat);
      }
      if (item.obj_profile) {
        item.obj_profile = JSON.parse(item.obj_profile);
      }
      if (item.obj_pachete_scrap) {
        item.obj_pachete_scrap = JSON.parse(item.obj_pachete_scrap);
      }
      if (item.obj_saci_scrap) {
        item.obj_saci_scrap = JSON.parse(item.obj_saci_scrap);
      }
      state.avize.push(item);
    });
  },
  loadAviz(state, item) {
    if (item.obj_mat) {
      item.obj_mat = JSON.parse(item.obj_mat);
    }
    if (item.obj_profile) {
      item.obj_profile = JSON.parse(item.obj_profile);
    }
    if (item.obj_pachete_scrap) {
      item.obj_pachete_scrap = JSON.parse(item.obj_pachete_scrap);
    }
    if (item.obj_saci_scrap) {
      item.obj_saci_scrap = JSON.parse(item.obj_saci_scrap);
    }
    if (item.obj_saci_span) {
      item.obj_saci_span = JSON.parse(item.obj_saci_span);
    }
    state.aviz = item;
  },
  updAvizDetalii(state, item) {
    state.aviz.nr_aviz = item.nr_aviz;
    state.aviz.nr_auto = item.nr_auto;
    state.aviz.delegat = item.delegat;
    state.aviz.data = item.data;
    state.aviz.ora = item.ora;
  },
};
