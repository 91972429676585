import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import moment from "moment";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    version: process.env.PACKAGE_VERSION,
    today: moment().format("YYYY-MM-DD"),
    ums: [
      {
        value: "kg",
        text: "KG",
      },
      {
        value: "ml",
        text: "ML",
      },
      {
        value: "m2",
        text: "M2",
      },
      {
        value: "buc",
        text: "BUC",
      },
      {
        value: "h",
        text: "Ora",
      },
      {
        value: "l",
        text: "Litru",
      },
    ],
    ral_colors: [
      {
        name: "RAL 7042",
        hex: "rgb(142, 146, 145)",
      },
      {
        name: "RAL 7005",
        hex: "rgb(108, 110, 107)",
      },
      {
        name: "RAL 7016",
        hex: "rgb(56, 62, 66)",
      },
      {
        name: "RAL 9005",
        hex: "rgb(14, 14, 16)",
      },
      {
        name: "RAL 9006",
        hex: "rgb(161, 161, 160)",
      },
      {
        name: "RAL 9016",
        hex: "rgb(241, 240, 234)",
      },
      {
        name: "RAL 3016",
        hex: "rgb(166, 61, 47)",
      },
      {
        name: "RAL 3009",
        hex: "rgb(109, 52, 45)",
      },

      {
        name: "RAL 8003",
        hex: "rgb(126, 75, 38)",
      },
      {
        name: "RAL 8004",
        hex: "rgb(141, 73, 49)",
      },
      {
        name: "RAL 8011",
        hex: "rgb(90, 56, 38)",
      },
      {
        name: "RAL 8017",
        hex: "rgb(68, 47, 41)",
      },
      {
        name: "RAL 8019",
        hex: "rgb(61, 54, 53)",
      },
      {
        name: "RAL 8023",
        hex: "rgb(164, 87, 41)",
      },
      {
        name: "RAL 5010",
        hex: "rgb(0, 79, 124)",
      },
      {
        name: "RAL 5012",
        hex: "rgb(0, 137, 182)",
      },
      {
        name: "RAL 6000",
        hex: "rgb(60, 116, 96)",
      },
      {
        name: "RAL 6005",
        hex: "rgb(17, 66, 50)",
      },
    ],
    judete: [
      {
        code: "AB",
        name: "Alba",
      },
      {
        code: "AR",
        name: "Arad",
      },
      {
        code: "AG",
        name: "Arges",
      },
      {
        code: "BC",
        name: "Bacau",
      },
      {
        code: "BH",
        name: "Bihor",
      },
      {
        code: "BN",
        name: "Bistrita-Nasaud",
      },
      {
        code: "BT",
        name: "Botosani",
      },
      {
        code: "BR",
        name: "Braila",
      },
      {
        code: "BV",
        name: "Brasov",
      },
      {
        code: "B",
        name: "Bucuresti",
      },
      {
        code: "BZ",
        name: "Buzau",
      },
      {
        code: "CL",
        name: "Calarasi",
      },
      {
        code: "CS",
        name: "Caras-Severin",
      },
      {
        code: "CJ",
        name: "Cluj",
      },
      {
        code: "CT",
        name: "Constanta",
      },
      {
        code: "CV",
        name: "Covasna",
      },
      {
        code: "DB",
        name: "Dambovita",
      },
      {
        code: "DJ",
        name: "Dolj",
      },
      {
        code: "GL",
        name: "Galati",
      },
      {
        code: "GR",
        name: "Giurgiu",
      },
      {
        code: "GJ",
        name: "Gorj",
      },
      {
        code: "HR",
        name: "Harghita",
      },
      {
        code: "HD",
        name: "Hunedoara",
      },
      {
        code: "IL",
        name: "Ialomita",
      },
      {
        code: "IS",
        name: "Iasi",
      },
      {
        code: "IF",
        name: "Ilfov",
      },
      {
        code: "MM",
        name: "Maramures",
      },
      {
        code: "MH",
        name: "Mehedinti",
      },
      {
        code: "MS",
        name: "Mures",
      },
      {
        code: "NT",
        name: "Neamt",
      },
      {
        code: "OT",
        name: "Olt",
      },
      {
        code: "PH",
        name: "Prahova",
      },
      {
        code: "SJ",
        name: "Salaj",
      },
      {
        code: "SM",
        name: "Satu Mare",
      },
      {
        code: "SB",
        name: "Sibiu",
      },
      {
        code: "SV",
        name: "Suceava",
      },
      {
        code: "TR",
        name: "Teleorman",
      },
      {
        code: "TM",
        name: "Timis",
      },
      {
        code: "TL",
        name: "Tulcea",
      },
      {
        code: "VL",
        name: "Valcea",
      },
      {
        code: "VS",
        name: "Vaslui",
      },
      {
        code: "VN",
        name: "Vrancea",
      },
    ],
    furnizoriVanzari: [
      { value: "Kloeckner", text: "Kloeckner" },
      { value: "Voss", text: "Voss" },
      { value: "Stoc", text: "Stoc" },
    ],
    target: 190000,
    currency: 4.7,
    p_bonus: {
      avans: 0.85,
      termen: 0.67,
      avans_lucrari: 4,
      termen_lucrari: 3,
    },
    // Banda rulou
    arr_benzi: [
      "Banda",
      "Banda vopsita",
      "Banda prevopsita",
      "Banda vopsita si prevopsita",
    ],
    // Bare patrate si rectangulare
    arr_bareP: ["Bara rectangulara", "Bara patrata"],
    // Table si placi
    arr_table: [
      "Tabla",
      "Placa",
      "Tabla dreapta (lisa)",
      "Tabla colorata",
      "Tabla striata",
      "Tabla eloxata",
      "Tabla diamond",
      "Tabla stucco",
      "Tabla cutata",
    ],
    // Tevi rotunde
    arr_teviR: [
      "Teava rotunda",
      "Teava rotunda sudata",
      "Teava rotunda trasa",
      "Teava rotunda vopsita",
      "Teava rotunda luciu",
      "Teava rotunda - diametru mare",
      "Teava luciu",
      "Teava trasa",
    ],
    // Tevi patrate si rectangulare
    arr_teviP: [
      "Teava rectangulara",
      "Teava rectangulara sudata",
      "Teava rectangulara trasa",
      "Teava rectangulara vopsita",
      "Teava patrata",
      "Teava patrata sudata",
      "Teava patrata trasa",
      "Teava patrata vopsita",
      "Profil vopsit",
      "Profil rectangular vopsit",
    ],
    // Profile L T
    arr_profileLT: [
      "Profil L",
      "Profil T",
      "Profil L vopsit",
      "Profil T vopsit",
    ],
    arr_profile_preturi: [
      "Bara hexagonala",
      "Bara patrata",
      "Bara rectangulara",
      "Bara rotunda",
      "Platbanda",
      "Profil I",
      "Profil L",
      "Profil T",
      "Profil U",
      "Teava patrata",
      "Teava rectangulara",
      "Teava rotunda",
    ],
    arr_table_preturi: ["Banda", "Tabla dreapta (lisa)", "Tabla stucco"],
    arr_profile_vopsite_preturi: [
      "Profil vopsit",
      "Profil rectangular vopsit",
      "Platbanda vopsita",
      "Profil I vopsit",
      "Profil L vopsit",
      "Profil T vopsit",
      "Profil U vopsit",
      "Teava patrata vopsita",
      "Teava rectangulara vopsita",
      "Teava rotunda vopsita",
    ],
    executant_firma_text:
      "1. S.C. CUPROLLI MANUFACTURING S.R.L., cu punct de lucru in Ghiroda, Str. Caprioarei, nr. 11, Jud. Timis, inregistrata la Registrul Comertului sub numarul J35/468/2016, cod fiscal RO35671874, cont bancar: RO42BTRLRONCRT0337578301, Banca Transilvania, telefon: 0732823808, reprezentata legal prin Rosu Octavian in calitate de EXECUTANT",
    montaj_firma1_text:
      "2. S.C. CUPROLLI TRADING S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis inregistrata in Registrul Comertului sub numarul J35/2578/2019, cod fiscal 41281760 (neplatitor de TVA), cont bancar: RO87BTRLRONCRT0478696001, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR",
    montaj_firma2_text:
      "2. S.C. CUPROLLI SERV S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis inregistrata in Registrul Comertului sub numarul J35/3370/2021, cod fiscal 44771643 (neplatitor de TVA), cont bancar: RO52BTRLRONCRT0615069901, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR",
    montaj_firma3_text:
      "2. S.C. CUPROLLI MONTAJE S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis inregistrata in Registrul Comertului sub numarul J35/4258/2021, cod fiscal 45109254 (neplatitor de TVA), cont bancar: RO83BTRLRONCRT0CE8963901, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR",
    montaj_firma4_text:
      "2. S.C. CUPROLLI STRUCTURI S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis inregistrata in Registrul Comertului sub numarul J35/2712/2022, cod fiscal 46303894 (neplatitor de TVA), cont bancar: RO45BTRLRONCRT0CH2829301, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR",
    montaj_firma5_text:
      "2. S.C. CUPROLLI DESIGN S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis inregistrata in Registrul Comertului sub numarul J35/3676/2022, cod fiscal 46619944 (neplatitor de TVA), cont bancar: RO74BTRLRONCRT0CH8981101, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR",
    loading: false,
    loadingCompanyDetails: false,
    loadingOferte: false,
    isOffersSearchList: false,
    loadingOferta: false,
    loadingClienti: false,
    loadingClient: false,
    loadingCereri: false,
    loadingCerere: false,
    loadingAliaje: false,
    loadingReports: false,
    loadingOferteGarduri: false,
    isFenceOffersSearchList: false,
    loadingOfertaGarduri: false,
    loadingOferteConfigurator: false,
    loadingListaReveniriConfigurator: false,
    loadingOferteConfiguratorEU: false,
    loadingProforme: false,
    isProformeSearchList: false,
    loadingProformeProductie: false,
    isProformeProductieSearchList: false,
    loadingContracte: false,
    isContractsSearchList: false,
    bonus: "",
    user: {},
    userLoaded: false,
    users: [],
    wpCereri: [],
    wpCerere: {},
    wpDetails: {},
    wpOrderReports: [],
    oferte: [],
    oferta: {},
    proforme: [],
    proforma: {},
    proformeProductie: [],
    proformaProductie: {},
    contracteProductie: [],
    contractProductie: {},
    aditionalExtra: {},
    automationList: [
      {
        value: "Kit automatizare premium poarta culisanta - BFT Deimos Ultra",
        text: "Kit automatizare premium poarta culisanta - BFT Deimos Ultra",
        um: "buc",
        price: 690,
        filename: "automatizari/bft-deimos-ultra.jpg",
      },
      {
        value: "Kit automatizare premium poarta culisanta - BFT Ares Veloce",
        text: "Kit automatizare premium poarta culisanta - BFT Ares Veloce",
        um: "buc",
        price: 750,
        filename: "automatizari/bft-ares-veloce.jpg",
      },
      {
        value: "Kit automatizare premium poarta batanta - BFT Kustos Ultra A40",
        text: "Kit automatizare premium poarta batanta - BFT Kustos Ultra A40",
        um: "buc",
        price: 790,
        filename: "automatizari/bft-kustos-ultra.jpg",
      },
      {
        value: "Kit automatizare premium poarta batanta - BFT Virgo",
        text: "Kit automatizare premium poarta batanta - BFT Virgo",
        um: "buc",
        price: 790,
        filename: "automatizari/bft-virgo.jpg",
      },
    ],
    comenzi_active: [],
    comenzi_finalizate: [],
    comenzi_by_user: [],
    current_comenzi_by_user: [],
    comenzi_bonus: [],
    comanda: {},
    contract: {
      id_doc: "",
      name: "",
      type: "",
      docURL: "",
    },
    factura: {
      id_doc: "",
      name: "",
      type: "",
      docURL: "",
    },
    awb: {
      id_doc: "",
      name: "",
      type: "",
      docURL: "",
    },
    curs: {},
    returnClientId: "",
    clientiCount: null,
    client: {},
    clienti: [],
    clientiData: [],
    profile: [],
    profil: {},
    echipeDeb: [],
    stocProfile: [],
    comenziDeb: [],
    comenziDebFinalizate: [],
    debitari: [],
    listaDebitari: [],
    debitare: {},
    pachete: {},
    saci: {},
    span: {},
    //setari
    materiale: [],
    listaProduse: [],
    aliaje: [],
    consumabile: [],
    manopera: [],
    preturiVanzari: [],
    formulaVanzari: {},
    preturiDebitare: [],
    preturiTransport: [],
    preturiVopsit: [],
    preturiServ: [],
    firme_montaj: [],
    furnizori: [],
    oferte_productie_garduri: [],
    oferte_productie_configurator_gard: [],
    lista_reveniri_configurator_gard_counts: [],
    lista_reveniri_configurator_gard: [],
    oferte_constructii_configurator_gard: [],
    oferte_productie_configurator_eu: [],
    oferte_productie_general: [],
    oferte_productie_by_user: [],
    oferte_productie_bonus: [],
    currentOfertaProductie: {},
    lucrari: [],
    currentLucrare: {},
    avize: [],
    aviz: {},
    objCerere: {},
    calendarEvents: [],
    oameni_productie: [],
    modele_gard: [],
    LME: {},
    levelsVanzari: [],
    fixedPricesMateriePrima: {},
  },
  getters,
  mutations,
  actions,
});

export default store;
