<template>
	<div>
		<v-app-bar color="medium-grey">
			<v-app-bar-nav-icon color="white" class="lg:hidden"></v-app-bar-nav-icon>
			<v-toolbar-title>
				<router-link class="inline-block w-20" to="/" exact>
					<img src="../assets/HUB_logo.png" />
				</router-link>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<div class="flex flex-row align-center justify-end gap-10">
				<div class="flex flex-row align-center justify-center gap-2">
					<v-menu v-if="isLoggedIn && ['admin', 'superuser', 'user_vanzari'].includes(user.rol)" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" class="uppercase" text v-bind="attrs" v-on="on">
								Vanzari
								<v-icon right>
									mdi-menu-down
								</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)"
								to="/cererisite">
								<v-icon class="mr-2">mdi-message-text</v-icon>
								<v-list-item-title>Cereri site</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)" to="/oferte">
								<v-icon class="mr-2">mdi-file-document-multiple</v-icon>
								<v-list-item-title>Oferte produse</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)" to="/comenzi">
								<v-icon class="mr-2">mdi-clipboard-text</v-icon>
								<v-list-item-title>Comenzi produse</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser', 'user_vanzari'].includes(user.rol)"
								to="/proforme">
								<v-icon class="mr-2">mdi-invoice-text</v-icon>
								<v-list-item-title>Proforme</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin'].includes(user.rol)" to="/rapoarte">
								<v-icon class="mr-2">mdi-chart-bar</v-icon>
								<v-list-item-title>Rapoarte</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-menu v-if="isLoggedIn && ['admin', 'superuser', 'user_productie'].includes(user.rol)" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" class="uppercase" text v-bind="attrs" v-on="on">
								Productie
								<v-icon right>
									mdi-menu-down
								</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-if="['admin', 'superuser', 'user_productie'].includes(user.rol)"
								to="/oferte-productie">
								<v-icon class="mr-2">mdi-file-document-multiple</v-icon>
								<v-list-item-title>Oferte garduri</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser'].includes(user.rol)" to="/lucrari-productie">
								<v-icon class="mr-2">mdi-robot-industrial</v-icon>
								<v-list-item-title>Lucrari</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser'].includes(user.rol)" to="/calendar-productie">
								<v-icon class="mr-2">mdi-calendar</v-icon>
								<v-list-item-title>Programare</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser', 'user_productie'].includes(user.rol)"
								to="/productie/proforme">
								<v-icon class="mr-2">mdi-invoice-text</v-icon>
								<v-list-item-title>Proforme</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="['admin', 'superuser', 'user_productie'].includes(user.rol)"
								to="/productie/contracte">
								<v-icon class="mr-2">mdi-file-sign</v-icon>
								<v-list-item-title>Contracte</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-btn
						v-if="isLoggedIn && ['admin', 'superuser', 'user_productie', 'user_vanzari'].includes(user.rol)"
						color="white" class="uppercase" text to="/clienti">
						Clienti
					</v-btn>

				</div>
				<div>
					<v-btn v-if="isLoggedIn && ['admin', 'superuser'].includes(user.rol)" icon color="white"
						class="uppercase mx-1" to="/setari">
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<v-menu v-if="isLoggedIn" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon color="white" v-bind="attrs" v-on="on">
								<v-icon>
									mdi-account-circle
								</v-icon>
							</v-btn>
						</template>
						<v-list class="pt-0">
							<v-list-item class="bg-main-grey">
								<v-list-item-title class="text-white">{{ user.username }}</v-list-item-title>
							</v-list-item>
							<v-list-item @click="logout">
								<v-icon class="mr-2">mdi-lock</v-icon>
								<v-list-item-title>Logout</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</v-app-bar>

	</div>
</template>

<script>
import firebase from "firebase/app";

export default {
	name: "Navbar",
	data() {
		return {
			// isLoggedIn: false,
		};
	},
	computed: {
		user() {
			return this.$store.getters.loggedUser;
		},
		isLoggedIn() {
			return firebase.auth().currentUser;
		},
	},
	methods: {
		logout() {
			firebase
				.auth()
				.signOut()
				.then(() => {
					this.$router.go({ path: this.$router.path });
				});
		},
	},
	created: function () { },
	mounted: function () {
		M.Sidenav.init(document.querySelectorAll(".sidenav"));
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"));
	},
	updated: function () {
		M.Sidenav.init(document.querySelectorAll(".sidenav"));
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"));
	},
};
</script>

<style scoped>
.v-btn {
	@apply uppercase
}
</style>
