<template>
	<div class="pb-10">
		<div>
			<div class="page-tabs mb-6">
				<ul class="container flex flex-wrap gap-4">
					<li class="">
						<router-link to="/oferte-productie/oferte-garduri"
							class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
							:class="{ active: currentRoute == 'OferteProductieGarduri' }">Oferte
							Garduri</router-link>
					</li>
					<li class="">
						<router-link to="/oferte-productie/oferte-configurator-ro"
							class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
							:class="{ active: currentRoute == 'OferteProductieConfiguratorGard' }">Configurator
							gard RO</router-link>
					</li>
					<li v-if="['admin', 'superuser'].includes(user.rol) || user.email_user == 'razvan.covaci@i-struct.ro'"
						class="">
						<router-link to="/oferte-productie/oferte-configurator-eu"
							class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
							:class="{ active: currentRoute == 'OferteProductieConfiguratorEU' }">Configurator
							gard EU</router-link>
					</li>
					<li v-if="['admin', 'superuser'].includes(user.rol)" class="">
						<router-link to="/oferte-productie/oferte-general"
							class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
							:class="{ active: currentRoute == 'OferteProductieGeneral' }">Oferte
							General</router-link>
					</li>
				</ul>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: "OferteProductie",
	computed: {
		user() {
			return this.$store.getters.loggedUser;
		},
		currentRoute() {
			return this.$route.name
		}
	}

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-tabs {
	@apply bg-white uppercase text-center text-gray-500 border-b border-gray-200 rounded-lg
}

.page-tabs li>.active {
	@apply text-blue-500 border-blue-500
}
</style>
